import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChildren,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

export type FabMenuItem = {
  icon: string;
  tooltip?: string;
  onClick?: () => any;
  href?: string;
};

@Component({
  selector: 'aa-fab-menu',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatTooltipModule],
  templateUrl: './fab-menu.component.html',
  styleUrl: './fab-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FabMenuComponent {
  @Input({ required: true }) menuItems!: FabMenuItem[];
  @Input() menuIcon = 'menu';

  @ViewChildren('tooltipRef')
  tooltips!: MatTooltip[];

  open$ = new BehaviorSubject(false);

  async toggleOpen() {
    const newValue = !(await firstValueFrom(this.open$));

    for (const tooltip of this.tooltips) {
      if (newValue) {
        tooltip.disabled = false;
        setTimeout(() => {
          tooltip.show();
        }, 500);
      } else {
        tooltip.hide();
        tooltip.disabled = true;
      }
    }

    this.open$.next(newValue);
  }
}
