<div class="container">
  <div class="header">
    <h1>Help</h1>
    <button mat-icon-button mat-dialog-close="">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <!-- <div class="image-holder"> -->
  <img [src]="data.imagePath" />
  <!-- </div> -->
  <p>
    For further assistance you can contact our support department
    <a href="https://allanglesinc.zendesk.com/hc/en-us">here</a>.
  </p>
</div>
