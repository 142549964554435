import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  AppBarComponent,
  authActions,
  AuthService,
  FabMenuComponent,
  FabMenuItem,
  HelpModalComponent,
  HelpModalData,
  selectAuth,
} from '@aa/angular/core';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  firstValueFrom,
  map,
} from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { CustomerAppState } from '../../state/index.reducers';
import { isInStandaloneMode } from '@aa/ts/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PaymentMethodSetupPopupComponent } from '../payment-method-setup-popup/payment-method-setup-popup.component';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import '@khmyznikov/pwa-install';

const unprotectedRoutes: string[] = [
  '/register',
  '/login',
  '/forgot-password',
  '/reset-password/[0-9]+/.+',
  '/terms-of-service',
  '/privacy-policy',
  '/order-slip/?.+?',
];

const hideLayoutRoutes: string[] = [
  '/register',
  '/login',
  '/forgot-password',
  '/reset-password/[0-9]+/.+',
  '/terms-of-service',
  '/privacy-policy',
  '/order-slip/[0-9]+',
];

const helpImageMapping: Record<string, string> = {
  '/': 'home-page-help.png',
  '/draft-editor/[0-9]+': 'draft-editor-help.png',
  '/draft-editor/[0-9]+/new-card': 'card-editor-help.png',
  '/draft-editor/[0-9]+/cards/[0-9]+': 'card-editor-help.png',
};

@Component({
  selector: 'aa-customer-app-layout',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    AppBarComponent,
    FabMenuComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './customer-app-layout.component.html',
  styleUrl: './customer-app-layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerAppLayoutComponent implements AfterViewInit {
  hideLayout$ = new BehaviorSubject(false);
  currentHelpImage$ = new BehaviorSubject<string | undefined>(undefined);
  showHelpImage$ = this.currentHelpImage$.pipe(map((v) => !!v));

  @ViewChild('pwaInstallPrompt') pwaInstallPrompt!: ElementRef;

  menuItems: FabMenuItem[] = [
    {
      tooltip: 'Get Support',
      icon: 'support_agent',
      href: 'https://allangles.freshdesk.com',
    },
  ];
  menuItemsWithHelpImage: FabMenuItem[] = [
    {
      tooltip: 'Tutorial',
      icon: 'lightbulb',
      onClick: () => this.showHelp(),
    },
    ...this.menuItems,
  ];

  constructor(
    private readonly store: Store<CustomerAppState>,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly authService: AuthService,
  ) {
    combineLatest([
      this.store.select((s) => selectAuth(s).user),
      this.router.events.pipe(
        filter((e) => e instanceof NavigationEnd),
        takeUntilDestroyed(),
      ),
    ]).subscribe(([user, routeEvent]) => {
      const urlSlug = routeEvent.url
        .substring(routeEvent.url.indexOf('/'))
        .split('?')?.[0];

      if (
        hideLayoutRoutes.find((regex) => new RegExp(`^${regex}$`).test(urlSlug))
      ) {
        this.hideLayout$.next(true);
      } else {
        this.hideLayout$.next(false);
      }

      if (
        !unprotectedRoutes.find((regex) =>
          new RegExp(`^${regex}$`).test(urlSlug),
        ) &&
        !user
      ) {
        this.store.dispatch(
          authActions.setOriginalPath({ path: routeEvent.url }),
        );
        this.router.navigate(['login']);
      }

      const matchingHelpImageKey = Object.keys(helpImageMapping).find((key) =>
        new RegExp(`^${key}$`).test(urlSlug),
      );
      if (matchingHelpImageKey) {
        this.currentHelpImage$.next(
          'assets/help-images/' + helpImageMapping[matchingHelpImageKey],
        );
      } else {
        this.currentHelpImage$.next(undefined);
      }
    });

    this.store
      .select((s) => selectAuth(s).user)
      .subscribe((user) => {
        if (user?.customerProfile) {
          this.store.dispatch(authActions.checkPaymentMethodStatus());
        }
      });

    this.store
      .select((s) => selectAuth(s).paymentMethodSetup)
      .pipe(takeUntilDestroyed())
      .subscribe(async (setup) => {
        console.log('SETUP', setup);
        if (setup == false) {
          const authState = await firstValueFrom(this.store.select(selectAuth));
          const layoutHidden = await firstValueFrom(this.hideLayout$);
          if (authState.user?.customerProfile && !layoutHidden) {
            // prompt for payment method setup
            this.dialog
              .open(PaymentMethodSetupPopupComponent, {
                disableClose: true,
              })
              .afterClosed();
          }
        }
      });
  }

  ngAfterViewInit() {
    const initiallyLoaded = localStorage.getItem('initiallyLoaded');
    if (initiallyLoaded != 'true') {
      this.showInstallPrompt();
      localStorage.setItem('initiallyLoaded', 'true');
    }
  }

  showInstallPrompt() {
    if (!isInStandaloneMode())
      (this.pwaInstallPrompt?.nativeElement as any).showDialog();
  }

  async showHelp() {
    const imagePath = await firstValueFrom(this.currentHelpImage$);

    this.dialog.open(HelpModalComponent, {
      data: {
        imagePath,
      } as HelpModalData,
      maxWidth: 'calc(100vw - 2rem)',
      maxHeight: 'calc(100vh - 2rem)',
      autoFocus: false,
    });
  }
}
