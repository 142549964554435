<div class="mini-app-bar">
  <a href="/"><img src="assets/images/small-logo.png" /></a>
</div>
<div class="content-holder">
  <div class="doc-content">
    <h1 class="c12"><span>Terms of Service for All Angles Inc.</span></h1>
    <h2 class="c8"><span class="c2">Introduction and Acceptance</span></h2>
    <p class="c3">
      <span class="c0"
        >Welcome to All Angles Inc. (&quot;Service Provider&quot;), a company
        dedicated to providing exceptional service. By accessing or using our
        services through the web application goallangles.com or allangles.ai,
        you (&quot;Customer&quot;) agree to be bound by the terms and conditions
        set forth in these Terms of Service (&quot;Agreement&quot;). This
        Agreement is effective upon your electronic acceptance by clicking
        &ldquo;I Agree&rdquo; or a similar button when prompted on our web
        application. &nbsp;</span
      >
    </p>
    <p class="c3 c5"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >Please read this Agreement carefully before accessing or using our
        services. By accessing or using any part of the services, you agree to
        become bound by the terms and conditions of this Agreement. If you do
        not agree to all the terms and conditions of this Agreement, then you
        may not access the services or use any services. If these terms and
        conditions are considered an offer by the Service Provider, acceptance
        is expressly limited to these terms.</span
      >
    </p>
    <p class="c3">
      <span class="c0"
        >The services are available only to individuals who are at least 18
        years old, unless we specify otherwise. By accessing or using the
        services, you represent and warrant that you are at least 18 years
        old.</span
      >
    </p>
    <h2 class="c8"><span class="c2">Description of Services</span></h2>
    <p class="c3">
      <span class="c0"
        >The Service Provider, All Angles Inc., agrees to provide the Customer,
        being either the Submitter or Card Owner, with a comprehensive suite of
        sports card and trading card evaluation, recommendation, preparation and
        submissions services.</span
      >
    </p>
    <p class="c3 c5"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >It is understood that the scope of services may be adjusted from time
        to time based on mutual agreement between the Service Provider and the
        Customer. Any changes to the scope of services will be documented in
        writing and will become an integral part of the Service Agreement.</span
      >
    </p>
    <p class="c3 c5"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >The Service Provider commits to using best practices and industry
        standards in the delivery of all services to ensure the highest quality
        of work. The Customer acknowledges that the success of the services
        provided depends on their cooperation and timely provision of necessary
        information and resources as may be required by the Service
        Provider.</span
      >
    </p>
    <h2 class="c8">
      <span class="c2">Assumption of Risk and Liability Limitation</span>
    </h2>
    <p class="c3">
      <span>The Customer </span><span>acknowledges</span
      ><span
        >&nbsp;and agrees that the use of services provided by the Service
        Provider, including but not limited to the preparation, grading, and
        shipping of trading and sports cards, involves inherent risks and
        potential for damage, loss, or theft</span
      ><span>.</span
      ><span class="c0"
        >&nbsp;By engaging the services of the Service Provider, the Customer
        expressly assumes all such risks, both known and unknown, related to the
        services provided under this Agreement and any Service Agreement entered
        into pursuant to this Agreement.</span
      >
    </p>
    <p class="c3 c5"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >Furthermore, the Service Provider shall not be liable for any direct,
        indirect, incidental, special, consequential, or exemplary damages,
        including but not limited to, damages for loss of profits, goodwill,
        use, data, or other intangible losses (even if the Service Provider has
        been advised of the possibility of such damages), resulting from: (i)
        the use or the inability to use the services; (ii) the cost of
        procurement of substitute goods and services resulting from any goods,
        data, information, or services purchased or obtained or messages
        received or transactions entered into through or from the services;
        (iii) unauthorized access to or alteration of your transmissions or
        data; (iv) statements or conduct of any third party on the service; or
        (v) any other matter relating to the services.</span
      >
    </p>
    <p class="c3 c5"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >Notwithstanding any other provision of this Agreement or any Service
        Agreement, the Service Provider&#39;s liability to the Customer, if any,
        shall in no event exceed the total amount paid by the Customer to the
        Service Provider per incident &nbsp;for the services rendered under this
        Agreement during the twelve (12) months preceding the claim.</span
      >
    </p>
    <h2 class="c8"><span class="c2">Customer Responsibilities</span></h2>
    <p class="c3">
      <span class="c0"
        >Under the Agreement, the Customer acknowledges and agrees to the
        following responsibilities:</span
      >
    </p>
    <ol class="c11 lst-kix_list_1-0 start" start="1">
      <li class="c4 li-bullet-0">
        <span class="c0"
          >Ensure accuracy and completeness of all information provided to the
          Service Provider in connection with the services rendered under the
          Service Agreement.</span
        >
      </li>
      <li class="c3 c6 li-bullet-0">
        <span class="c0"
          >Abide by all terms and conditions set forth in the Service Agreement,
          including any amendments or modifications thereto.</span
        >
      </li>
      <li class="c3 c6 li-bullet-0">
        <span class="c0"
          >Be solely responsible for any upcharges from third-party grading
          companies, which may include, but are not limited to, value increases
          after grading, service level increases, autograph verification
          increases, or holder size increases. These upcharges are a result of
          the valuation and assessment processes conducted by third-party
          grading companies and are beyond the control of the Service
          Provider.</span
        >
      </li>
      <li class="c3 c6 li-bullet-0">
        <span class="c0"
          >Accept all Inherent Risks associated with the services provided,
          including but not limited to damage, loss, or theft of trading and
          sports cards.</span
        >
      </li>
      <li class="c6 c13 li-bullet-0">
        <span>Indemnify</span
        ><span>&nbsp;and hold harmless the Service Provider</span
        ><span
          >&nbsp;from any claims, damages, losses, liabilities, costs, and
          expenses (including reasonable attorneys&#39; fees</span
        ><span>) arising out of or in connection with</span
        ><span
          >&nbsp;the Customer&#39;s breach of any representations, warranties,
          or obligations under the Agreement or the Service Agreement</span
        ><span>.</span>
      </li>
    </ol>
    <p class="c3">
      <span class="c0"
        >This clause is governed by the laws of the jurisdiction set forth in
        the Agreement, and any disputes arising under this clause will be
        resolved in the specified jurisdiction.</span
      >
    </p>
    <h2 class="c8"><span class="c2">Dispute Resolution</span></h2>
    <p class="c3">
      <span class="c0"
        >In the event of any dispute, controversy, or claim arising out of or
        relating to this Agreement, or the breach, termination, or invalidity
        thereof (&quot;Dispute&quot;), the parties shall first endeavor to
        settle the Dispute through mediation. The mediation shall be conducted
        by a single mediator mutually agreed upon by the parties, and this
        process shall be initiated by either party by sending a written notice
        to the other party of its desire to mediate. The mediation shall take
        place in Lake or Cook County, Illinois, and shall be conducted in
        accordance with the rules of the American Arbitration Association,
        unless otherwise agreed upon by the parties.</span
      >
    </p>
    <p class="c3">
      <span class="c0"
        >If the Dispute cannot be resolved through mediation within sixty (60)
        days of the initiation of mediation, then either party may proceed to
        litigation. Any litigation under this Agreement shall be brought in any
        Circuit Court or Federal Court sitting in Lake or Cook County, Illinois,
        and both parties hereby submit to the exclusive jurisdiction of these
        courts for the purposes of any such litigation.</span
      >
    </p>
    <p class="c3 c5"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >Nothing in this clause shall prevent either party from seeking
        injunctive or other equitable relief from the courts for matters related
        to data security, intellectual property, or unauthorized access to the
        Service.</span
      >
    </p>
    <h2 class="c8"><span class="c2">Governing Law</span></h2>
    <p class="c3">
      <span class="c0"
        >This Agreement and any action related thereto shall be governed by and
        construed in accordance with the laws of the State of Illinois, without
        regard to its conflict of laws principles. Any legal suit, action, or
        proceeding arising out of, or related to, this Agreement or the services
        provided hereunder shall be instituted exclusively in the federal courts
        of the United States or the courts of the State of Illinois in each case
        located in the city and county of Lake or Cook. Each party consents to
        the jurisdiction of such courts and waives any objection to the laying
        of venue of any such suit, action, or proceeding in such courts.</span
      >
    </p>
    <h2 class="c8">
      <span class="c2">Amendments to the Terms of Service</span>
    </h2>
    <p class="c3">
      <span class="c0"
        >Any amendments made to the Agreement will be communicated to the
        Customer via email or through a notification on the Service
        Provider&rsquo;s websites, goallangles.com or allangles.ai, at least 30
        days prior to the changes taking effect. &nbsp;The Customer has the
        right to terminate the Agreement if the amendments are not acceptable,
        provided that such termination is communicated to the Service Provider
        in writing within 15 days of the amendment notification. &nbsp;Continued
        use of the services provided by the Service Provider after any such
        changes shall constitute the Customer&#39;s consent to such changes.
        &nbsp;</span
      >
    </p>
    <p class="c3 c5"><span class="c0"></span></p>
    <h2 class="c8"><span class="c2">Termination</span></h2>
    <p class="c3">
      <span class="c0"
        >This Agreement may be terminated by either the Service Provider or the
        Customer at any time, with or without cause, upon written notice to the
        other party. Upon termination of this Agreement for any reason, all
        rights and obligations of the parties under this Agreement shall cease,
        except for any rights and obligations that have accrued prior to the
        date of termination, and any obligations that are intended to survive
        termination as set forth in this Agreement.</span
      >
    </p>
    <p class="c3 c5"><span class="c0"></span></p>
    <p class="c3">
      <span
        >The Service Provider may also terminate this Agreement immediately,
        without notice, if the Customer fails to comply with any terms and
        conditions of this Agreement or any Service Agreement</span
      ><span
        >. In the event of such termination, the Customer shall not be entitled
        to a refund of any fees paid in advance for services not yet
        rendered</span
      ><span>.</span>
    </p>
    <p class="c3">
      <span class="c0"
        >Upon termination, the Customer must cease all use of the services
        provided by the Service Provider and destroy all copies, full or
        partial, of any documentation received in relation to the
        services.</span
      >
    </p>
    <p class="c3">
      <span class="c0"
        >Any dispute arising out of or in connection with the termination of
        this Agreement shall be resolved in accordance with the Dispute
        Resolution clause of this Agreement.</span
      >
    </p>
    <h2 class="c8"><span class="c2">Miscellaneous</span></h2>
    <p class="c3">
      <span class="c0"
        >This Agreement and any Service Agreement entered into between the
        Service Provider and the Customer constitute the entire agreement
        between the parties with respect to the subject matter hereof and
        supersede all prior agreements, understandings, negotiations, and
        discussions, whether oral or written, between the parties. No amendment
        to or modification of this Agreement shall be binding unless in writing
        and signed by the parties hereto.</span
      >
    </p>
    <p class="c3 c5"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >Neither this Agreement nor any rights or obligations hereunder may be
        assigned by either party (except third party grading and encapsulation
        when requested by customer) without the prior written consent of the
        other party, except that the Service Provider may assign this Agreement
        to any of its affiliates or to any successor in interest of its
        business.</span
      >
    </p>
    <p class="c3 c5"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >Any notice required or permitted to be given under this Agreement shall
        be in writing and may be delivered electronically to the email address
        provided by the Customer or through a notification on the Service
        Provider&rsquo;s websites goallangles.com or allangles.ai.</span
      >
    </p>
    <p class="c3 c5"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >If any provision of this Agreement is held to be invalid, illegal, or
        unenforceable, the validity, legality, and enforceability of the
        remaining provisions shall not in any way be affected or impaired
        thereby. The parties agree to negotiate in good faith to replace such
        invalid, illegal, or unenforceable provision with a valid provision that
        most closely approximates the intent and economic effect of the invalid
        provision.</span
      >
    </p>
    <p class="c3 c5"><span class="c0"></span></p>
    <p class="c3">
      <span
        >This Agreement may be executed in counterparts, each of which shall be
        deemed an original, but all of which together shall constitute one and
        the same instrument.</span
      >
    </p>
  </div>
</div>
