import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'aa-terms-of-service-view',
  standalone: true,
  imports: [],
  templateUrl: './terms-of-service-view.component.html',
  styleUrl: './terms-of-service-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsOfServiceViewComponent { }
