import { ActivatedRoute } from '@angular/router';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  startWith,
} from 'rxjs';
import {
  CardAttributeDetectionService,
  ConfirmDialogComponent,
  ConfirmDialogData,
  CoreAppState,
  offeredServiceActions,
  orderCardActions,
  selectOfferedServiceState,
  selectOrderCardState,
  StorageService,
} from '@aa/angular/core';
import { CommonModule, Location } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OfferedService } from '@prisma/client';
import { OrderCardResourceTypeMappings } from '@aa/nest/resource';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UPLOAD_TYPE } from '@aa/nest/storage/objects';
import { ViewBarComponent } from '../../components/view-bar/view-bar.component';
import {
  MultipleRequestedServicesFormFieldValue,
  RequestedServicesFormFieldValue,
} from '@aa/angular/staff';
import { formatUSD } from '@aa/ts/common';
import { MatMenuModule } from '@angular/material/menu';

export type CardFormModel = {
  tradingCardType?: string;
  requestedServices: Record<number, boolean>;
  characterName?: string;
  issue?: string;
  issuedYear?: string;
  cardNumber?: string;
  pokemonType?: string;
  setName?: string;
  playerName?: string;
  productionCompany?: string;
  serialNumber?: string;
  variation?: string;
  requestedGradingCompany?: string;
  minimumGradeForSubmission?: string;
  notes?: string;
  value?: number;
};
export const cardFormArrayFields = ['pokemonType'];

@Component({
  selector: 'aa-new-card-view',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    FormlyModule,
    ViewBarComponent,
  ],
  templateUrl: './new-card-view.component.html',
  styleUrl: './new-card-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewCardViewComponent implements AfterViewInit {
  form = new FormGroup({});
  model: CardFormModel = {
    requestedServices: [],
    cardNumber: '',
    characterName: '',
    issuedYear: '',
    notes: '',
    playerName: '',
    pokemonType: '',
    productionCompany: '',
    serialNumber: '',
    setName: '',
    variation: '',
  };
  fields: FormlyFieldConfig[] = [];

  cardId$: Observable<number | undefined>;
  card$: Observable<
    OrderCardResourceTypeMappings['resourceWithRelationsT'] | null | undefined
  >;
  draftId$: Observable<number | undefined>;
  isEditMode$: Observable<boolean>;

  frontImageFile$ = new BehaviorSubject<File | undefined>(undefined);
  frontImageStorageSlug$ = new BehaviorSubject<string | undefined>(undefined);
  backImageFile$ = new BehaviorSubject<File | undefined>(undefined);
  backImageStorageSlug$ = new BehaviorSubject<string | undefined>(undefined);
  frontImageDataURL$: Observable<string | undefined>;
  backImageDataURL$: Observable<string | undefined>;

  aiCardType$ = new BehaviorSubject<string | undefined>(undefined);
  aiLoading$ = new BehaviorSubject(false);

  constructor(
    private readonly store: Store<CoreAppState>,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly storageService: StorageService,
    private readonly cardAttributeDetectionService: CardAttributeDetectionService,
    private readonly dialog: MatDialog,
    private readonly destroyRef: DestroyRef,
  ) {
    this.card$ = this.store
      .select((s) => selectOrderCardState(s).current)
      .pipe(takeUntilDestroyed());
    this.isEditMode$ = this.card$.pipe(map((card) => !!card));

    this.draftId$ = this.route.paramMap.pipe(
      map((paramMap) => parseInt(paramMap.get('draftId')!)),
      takeUntilDestroyed(),
    );
    this.cardId$ = this.route.paramMap.pipe(
      map((paramMap) => parseInt(paramMap.get('cardId')!)),
      takeUntilDestroyed(),
    );

    getCardFormFieldsAndLoadOptions(store, destroyRef).subscribe(
      (formFields) => {
        this.fields = formFields;
      },
    );

    // load card
    this.cardId$.pipe(distinctUntilChanged()).subscribe((cardId) => {
      if (cardId) {
        this.store.dispatch(
          orderCardActions.loadItem({
            id: cardId,
            include: {
              orderCardServiceLinkages: {
                include: {
                  offeredService: {
                    include: {
                      parentOfferedService: true,
                    },
                  },
                },
              },
              orderCardAttributes: {
                include: {
                  orderCardAttributeType: true,
                },
              },
            },
          }),
        );
      } else {
        this.store.dispatch(orderCardActions.clearCurrent());
      }
    });

    // handle image previews
    this.frontImageDataURL$ = new Observable((observer) => {
      this.card$.subscribe((card) => {
        if (card) {
          observer.next(card.frontImageURL);
        }
      });
      this.frontImageFile$.subscribe((file) => {
        if (file) {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            if (typeof fileReader.result !== 'object') {
              observer.next(fileReader.result);
            }
          };
          fileReader.readAsDataURL(file);
        }
      });
    });
    this.backImageDataURL$ = new Observable((observer) => {
      this.card$.subscribe((card) => {
        if (card) {
          observer.next(card.backImageURL);
        }
      });
      this.backImageFile$.subscribe((file) => {
        if (file) {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            if (typeof fileReader.result !== 'object')
              observer.next(fileReader.result);
          };
          fileReader.readAsDataURL(file);
        }
      });
    });

    combineLatest([
      this.frontImageStorageSlug$.pipe(distinctUntilChanged()),
      this.backImageStorageSlug$.pipe(distinctUntilChanged()),
    ])
      .pipe(takeUntilDestroyed())
      .subscribe(async ([frontImageSlug, backImageSlug]) => {
        if (frontImageSlug && backImageSlug) {
          try {
            this.aiLoading$.next(true);

            const res = await lastValueFrom(
              this.cardAttributeDetectionService.detectAttributes({
                frontImageStorageSlug: frontImageSlug,
                backImageStorageSlug: backImageSlug,
              }),
            );
            this.form.patchValue({
              tradingCardType:
                res.tradingCardType[0] ?? this.model.tradingCardType,
            });
            setTimeout(() => {
              for (const [key, valueMapping] of Object.entries(res).filter(
                ([key, value]) => key !== 'tradingCardType',
              )) {
                const field = this.form.get(key);
                if (field) {
                  if (cardFormArrayFields.includes(key)) {
                    field.patchValue(
                      valueMapping[0]?.length > 0
                        ? JSON.parse(valueMapping[0])
                        : [],
                    );
                  } else {
                    field.patchValue(valueMapping[0]);
                  }
                }
              }
              this.aiLoading$.next(false);
            }, 50);
          } catch (e) {
            console.log('Failed to detect card attributes', e);
            this.aiLoading$.next(false);
          }
        }
      });

    this.aiLoading$.subscribe((loading) => {
      if (loading) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    });
  }

  ngAfterViewInit(): void {
    this.card$?.subscribe((card) => {
      const tradingCardType = card?.orderCardAttributes?.find(
        (ca) => ca.orderCardAttributeType?.fieldName == 'tradingCardType',
      )?.value;

      const patchValue: Record<string, any> = {
        ...this.model,
        tradingCardType,
        requestedServices: card?.orderCardServiceLinkages
          ?.filter((li) => !li.offeredService?.parentOfferedServiceId)
          ?.map((sl) => sl.offeredServiceId)
          .reduce(
            (obj, id) => ({ ...obj, [id]: true }),
            {} as Record<number, boolean>,
          ),
        requestedGradingCompany: card?.requestedGradingCompany,
        minimumGradeForSubmission: card?.minimumGradeForSubmission,
        ...card?.orderCardAttributes
          ?.filter((attribute) => attribute.value)
          ?.reduce(
            (obj, attribute) => ({
              ...obj,
              [attribute.orderCardAttributeType?.fieldName]:
                cardFormArrayFields.includes(
                  attribute.orderCardAttributeType?.fieldName,
                ) &&
                attribute.value &&
                attribute.value.includes('[') &&
                attribute.value.includes(']')
                  ? JSON.parse(attribute.value)
                  : attribute.value,
            }),
            {} as CardFormModel,
          ),
      };
      for (const serviceLinkage of card?.orderCardServiceLinkages ?? []) {
        const service = serviceLinkage.offeredService;
        if (service.parentOfferedService) {
          const key = `${(
            service.parentOfferedService?.abbreviation ??
            service.parentOfferedService?.name
          )
            .toLowerCase()
            .replaceAll(' ', '')}${service.group?.replaceAll(' ', '')}Addon`;

          if (patchValue[key]) {
            if (Array.isArray(patchValue[key])) {
              patchValue[key] = [...patchValue[key], service.id];
            } else {
              patchValue[key] = [patchValue[key], service.id];
            }
          } else {
            patchValue[key] = service.id;
          }
        }
      }

      this.model = {
        ...(patchValue as any),
      };

      this.form.patchValue({
        ...patchValue,
      });
    });
  }

  async handleImageUpload(event: Event, isBack = false) {
    const file = (event.target as HTMLInputElement)?.files?.[0];
    const card = await firstValueFrom(this.card$);
    const backImageSlug = await firstValueFrom(this.backImageStorageSlug$);
    const frontImageSlug = await firstValueFrom(this.frontImageStorageSlug$);
    if (file) {
      const uploadedFile = await lastValueFrom(
        this.storageService.uploadFile(
          file,
          isBack ? UPLOAD_TYPE.CARD_BACK : UPLOAD_TYPE.CARD_FRONT,
        ),
      );

      if (isBack) {
        this.backImageFile$.next(file);
        this.backImageStorageSlug$.next(uploadedFile.path);
        if (!frontImageSlug && card?.frontImageStorageSlug)
          this.frontImageStorageSlug$.next(card?.frontImageStorageSlug);
      } else {
        this.frontImageFile$.next(file);
        this.frontImageStorageSlug$.next(uploadedFile.path);
        if (!backImageSlug && card?.backImageStorageSlug)
          this.backImageStorageSlug$.next(card?.backImageStorageSlug);
      }
    }
  }

  cancel() {
    this.location.back();
  }

  async promptDelete() {
    const data: ConfirmDialogData = {
      title: 'Are you sure you want to delete this card?',
      subtitle: 'This action cannot be undone.',
    };
    const confirmation = await lastValueFrom(
      this.dialog
        .open(ConfirmDialogComponent, {
          autoFocus: false,
          data,
        })
        .afterClosed(),
    );
    const cardId = await firstValueFrom(this.cardId$);
    if (confirmation && cardId) {
      this.store.dispatch(orderCardActions.deleteItem({ id: cardId }));
      this.location.back();
    }
  }

  async save() {
    console.log('MODEL', this.model);
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      console.log('INVALID', this.form);
      return;
    }

    const orderId = await firstValueFrom(this.draftId$);
    const cardId = await firstValueFrom(this.cardId$);
    const currentOrderCardAttributes = await firstValueFrom(
      this.card$.pipe(map((card) => card?.orderCardAttributes)),
    );
    const frontImageStorageSlug = await firstValueFrom(
      this.frontImageStorageSlug$,
    );
    const backImageStorageSlug = await firstValueFrom(
      this.backImageStorageSlug$,
    );
    const isEdit = await firstValueFrom(this.isEditMode$);

    let requestedServices = [
      ...Object.entries(this.model.requestedServices)
        .filter(([key, value]) => value == true)
        .map(([key, value]) => parseInt(key)),
    ];
    for (const [key, value] of Object.entries(this.model)) {
      if (key.substring(key.length - 5) == 'Addon') {
        if (Array.isArray(value)) {
          requestedServices = [...requestedServices, ...value];
        } else {
          requestedServices = [...requestedServices, value as number];
        }
      }
    }

    const model = { ...(this.form.value as any) };
    const attributeKeys = reduceFieldsToKeys(this.fields);

    let generatedCardLabel = (
      ['pokemon'].includes(model.tradingCardType!)
        ? `${model.characterName ?? ''} ${model.issuedYear ?? ''} ${
            model.setName ?? ''
          } ${model.cardNumber ? `#${model.cardNumber}` : ''}`
        : `${model.playerName} ${model.issuedYear} ${model.issue ?? ''} ${
            model.cardNumber ? `#${model.cardNumber}` : ''
          } ${model.serialNumber ?? ''}`
    )?.replaceAll('  ', ' ');
    if (generatedCardLabel.endsWith(' ')) {
      generatedCardLabel = generatedCardLabel.substring(
        0,
        generatedCardLabel.length - 1,
      );
    }

    if (isEdit && cardId) {
      this.store.dispatch(
        orderCardActions.updateCardWithAttributes({
          id: cardId,
          cardUpdates: {
            label: generatedCardLabel,
            frontImageStorageSlug: frontImageStorageSlug,
            backImageStorageSlug: backImageStorageSlug,
            requestedGradingCompany: model.requestedGradingCompany,
            minimumGradeForSubmission: model.minimumGradeForSubmission,
          },
          offeredServicesIds: requestedServices,
          attributes: attributeKeys
            .filter(
              (key) =>
                key !== 'requestedServices' &&
                key !== 'requestedGradingCompany' &&
                key.substring(key.length - 5) != 'Addon',
            )
            .map((key) => ({
              orderCardId: cardId,
              orderCardAttributeTypeId: currentOrderCardAttributes?.find(
                (ca) => ca.orderCardAttributeType.fieldName == key,
              )?.orderCardAttributeTypeId,
              fieldName: key,
              value:
                cardFormArrayFields.includes(key) && model[key]
                  ? JSON.stringify(model[key])
                  : model[key],
            })),
        }),
      );
      this.location.back();
    } else if (orderId && frontImageStorageSlug && backImageStorageSlug) {
      this.store.dispatch(
        orderCardActions.createCardWithAttributes({
          cardData: {
            orderId: orderId,
            label: generatedCardLabel,
            frontImageStorageSlug: frontImageStorageSlug,
            backImageStorageSlug: backImageStorageSlug,
            requestedGradingCompany: model.requestedGradingCompany,
            minimumGradeForSubmission: model.minimumGradeForSubmission,
          },
          offeredServicesIds: requestedServices,
          attributes: attributeKeys
            .filter(
              (key) =>
                key !== 'requestedServices' &&
                key.substring(key.length - 5) != 'Addon',
            )
            .map((key) => ({
              fieldName: key,
              value:
                cardFormArrayFields.includes(key) && model[key]
                  ? JSON.stringify(model[key])
                  : model[key],
            })),
        }),
      );

      this.location.back();
    }
  }

  isNonIOSMobileDevice() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|playbook|silk/i.test(
          a,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4),
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || (window as any).opera);
    const platform =
      navigator.platform ?? (navigator as any).userAgentData?.platform;
    if (
      platform.toLocaleLowerCase().includes('iphone') ||
      platform.toLocaleLowerCase().includes('ipad') ||
      platform.toLocaleLowerCase().includes('ipod')
    ) {
      return false;
    }
    return check;
  }
}

interface AddonServiceConfig {
  key: string;
  parentServiceId: number;
  title: string;
  services: OfferedService[];
}

export const reduceFieldsToKeys = (fields: FormlyFieldConfig[]): string[] =>
  fields.reduce(
    (keys, field) =>
      field.key
        ? [...keys, field.key as string]
        : [...keys, ...reduceFieldsToKeys(field.fieldGroup ?? [])],
    [] as string[],
  );

export const getCardFormFieldsAndLoadOptions = (
  store: Store<CoreAppState>,
  destroyRef: DestroyRef,
  forStaffView = false,
) => {
  store.dispatch(
    offeredServiceActions.loadItems({
      query: {
        where: {
          level: 1,
          isStaffManageableOnly: forStaffView
            ? undefined
            : {
                not: true,
              },
        },
        include: {
          parentOfferedService: true,
        },
      },
    }),
  );
  const offeredServices$ = store
    .select((s) => selectOfferedServiceState(s).items)
    .pipe(takeUntilDestroyed(destroyRef));

  return new Observable<FormlyFieldConfig[]>((observer) => {
    offeredServices$.subscribe((services) => {
      let addonServices: AddonServiceConfig[] = [];
      for (const service of services) {
        if (service.parentOfferedService) {
          const key = `${(
            service.parentOfferedService?.abbreviation ??
            service.parentOfferedService?.name
          )
            .toLowerCase()
            .replaceAll(' ', '')}${service.group?.replaceAll(' ', '')}`;
          const existing = addonServices.find((addon) => addon.key == key);
          if (existing) {
            addonServices = [
              ...addonServices.map((addon) =>
                addon.key == key
                  ? { ...addon, services: [...addon.services, service] }
                  : { ...addon },
              ),
            ];
          } else {
            addonServices = [
              ...addonServices,
              {
                key,
                parentServiceId: service.parentOfferedServiceId!,
                title: `${
                  service.parentOfferedService?.abbreviation ??
                  service.parentOfferedService?.name
                } ${service.group}`,
                services: [service],
              },
            ];
          }
        }
      }

      const formFields: FormlyFieldConfig[] = [
        {
          key: 'requestedServices',
          type: forStaffView ? 'requested-services' : 'multicheckbox',
          className: 'checkbox-list',
          props: {
            label: 'Requested Services',
            options: offeredServices$.pipe(
              map((items) =>
                items
                  .filter((item) => !item.parentOfferedServiceId)
                  .map((item) => ({
                    label: `${item.name}${
                      item.abbreviation ? ` (${item.abbreviation}) ` : ''
                    }${
                      item.price
                        ? ` - ${formatUSD(item.price as any as number)}`
                        : ''
                    }`,
                    value: item.id,
                    meta: item,
                  })),
              ),
            ),
            multiple: true,
            required: true,
          },
          hooks: {
            onInit: async (field) => {
              if (forStaffView) {
                field.formControl?.valueChanges.subscribe((value) => {
                  const isDraft = (value as RequestedServicesFormFieldValue)
                    ?.isDraft;
                  const addonFields = addonServices
                    .filter((addon) => addon.services.length > 0)
                    .map(
                      (addon) =>
                        field?.parent?.fieldGroup?.find(
                          (field) => field.key == `${addon.key}Addon`,
                        ),
                    );

                  for (const addonField of addonFields) {
                    if (
                      (addonField?.props as any)?.externalDraftToggleObservable
                    ) {
                      setTimeout(() => {
                        (
                          (addonField!.props as any)
                            .externalDraftToggleObservable as BehaviorSubject<boolean>
                        ).next(!!isDraft);
                      }, 10);
                    }
                  }
                });
              }

              let options = field.props?.options;
              let optionsObs = new BehaviorSubject<
                { label: any; value: any; meta?: any }[]
              >([]);

              if (options) {
                if (!Array.isArray(options)) {
                  const obsRef = options;
                  console.log(obsRef);
                  obsRef.subscribe((newOptions) => {
                    options = newOptions;
                  });
                }
                field.props!.options = optionsObs;

                // prompt to refresh since changing options obs
                setTimeout(() => {
                  field.formControl?.setValue(field.formControl.value);
                }, 500);

                if (options) {
                  field.form
                    ?.get('tradingCardType')
                    ?.valueChanges?.pipe(
                      startWith(field.form?.get('tradingCardType')?.value),
                    )
                    ?.subscribe(async (tradingCardType) => {
                      if (Array.isArray(options)) {
                        const filteredOptions = options.filter(
                          (o) =>
                            !o.meta?.applicableCardTypes ||
                            o.meta?.applicableCardTypes.includes(
                              tradingCardType,
                            ),
                        );
                        console.log(options, tradingCardType, filteredOptions);
                        optionsObs.next(filteredOptions);
                      }
                    });
                }
              }
            },
          },
        },
        ...addonServices
          .filter((addon) => addon.services.length > 0)
          .map(
            (addon) =>
              ({
                key: `${addon.key}Addon`,
                type: forStaffView ? 'requested-services' : 'select',
                props: {
                  label: addon.title,
                  options: [
                    { label: 'None', value: null },
                    ...addon.services.map((item) => ({
                      label: `${item.name}${
                        item.price
                          ? ` - ${formatUSD(item.price as any as number)}`
                          : ''
                      }`,
                      value: item.id,
                    })),
                  ],
                  disableDraftToggle: true,
                  externalDraftToggleObservable: new BehaviorSubject(false),
                },
                expressions: {
                  'props.disabled': (field) =>
                    !(
                      field.form?.get('requestedServices')?.value?.isDraft
                        ? field.form?.get('requestedServices')?.value
                            ?.draftValues
                        : field.form?.get('requestedServices')?.value?.values
                    )?.includes(addon.parentServiceId),
                  hide: (field) =>
                    // forStaffView
                    //   ? !(
                    //       field.form?.get('requestedServices')?.value?.isDraft
                    //         ? field.form?.get('requestedServices')?.value
                    //             ?.draftValues
                    //         : field.form?.get('requestedServices')?.value
                    //             ?.values
                    //     )?.includes(addon.parentServiceId)
                    //   :
                    // !field.form?.get('requestedServices')?.value?.[
                    //   addon.parentServiceId
                    // ] == true ||
                    !forStaffView,
                },
              }) as FormlyFieldConfig,
          ),
        {
          key: 'tradingCardType',
          type: 'select',
          props: {
            label: 'Card Type',
            options: [
              'Pokemon',
              'Baseball',
              'Basketball',
              'Boxing',
              'Cricket',
              'Football',
              'Golf',
              'Hockey',
              'Racing',
              'Soccer',
              'Tennis',
              'Wrestling',
            ].map((o) => ({ label: o, value: o.toLowerCase() })),
            required: true,
          },
        },
        // pokemon
        {
          key: 'characterName',
          type: 'input',
          props: {
            label: 'Character Name',
            required: true,
          },
          expressions: {
            hide: (field) =>
              field.form?.get('tradingCardType')?.value !== 'pokemon',
          },
        },
        {
          key: 'pokemonType',
          type: 'select',
          props: {
            label: 'Pokemon Type',
            multiple: true,
            options: [
              'Normal',
              'Fire',
              'Water',
              'Electric',
              'Grass',
              'Ice',
              'Fighting',
              'Poison',
              'Ground',
              'Flying',
              'Psychic',
              'Bug',
              'Rock',
              'Ghost',
              'Dragon',
              'Dark',
              'Steel',
              'Fairy',
            ].map((o) => ({ label: o, value: o.toLowerCase() })),
            required: true,
          },
          expressions: {
            hide: (field) =>
              field.form?.get('tradingCardType')?.value !== 'pokemon',
          },
        },
        {
          key: 'setName',
          type: 'input',
          props: {
            label: 'Set Name',
            required: true,
          },
          expressions: {
            hide: (field) =>
              field.form?.get('tradingCardType')?.value !== 'pokemon',
          },
        },
        // sports
        {
          key: 'playerName',
          type: 'input',
          props: {
            label: 'Player Name',
            required: true,
          },
          expressions: {
            hide: (field) =>
              !field.form?.get('tradingCardType')?.value ||
              field.form?.get('tradingCardType')?.value == 'pokemon',
          },
        },
        {
          key: 'productionCompany',
          type: 'input',
          props: {
            label: 'Production Company',
            required: true,
          },
          expressions: {
            hide: (field) =>
              !field.form?.get('tradingCardType')?.value ||
              field.form?.get('tradingCardType')?.value == 'pokemon',
          },
        },
        {
          key: 'issue',
          type: 'input',
          props: {
            label: 'Issue',
          },
          expressions: {
            hide: (field) =>
              !field.form?.get('tradingCardType')?.value ||
              field.form?.get('tradingCardType')?.value == 'pokemon',
          },
        },
        // both
        {
          fieldGroupClassName: 'flex-row-field-group',
          fieldGroup: [
            {
              key: 'cardNumber',
              type: 'input',
              props: {
                label: 'Card Number',
                required: true,
              },
            },
            {
              key: 'issuedYear',
              type: 'input',
              props: {
                label: 'Issued Year',
                required: true,
              },
            },
          ],
        },
        // sports
        {
          key: 'serialNumber',
          type: 'input',
          props: {
            label: 'Serial Number',
          },
          expressions: {
            hide: (field) =>
              !field.form?.get('tradingCardType')?.value ||
              field.form?.get('tradingCardType')?.value == 'pokemon',
          },
        },
        // both
        {
          key: 'variation',
          type: 'input',
          props: {
            label: 'Variation',
            placeholder: 'Is this card special in any way?',
          },
          expressions: {
            hide: (field) => !field.form?.get('tradingCardType')?.value,
          },
        },
        {
          key: 'minimumGradeForSubmission',
          type: 'input',
          props: {
            label: 'Minimum Grade for Submission',
            required: true,
          },
          expressions: {
            hide: (field) =>
              !field.form?.get('tradingCardType')?.value ||
              !(forStaffView
                ? (
                    field.form?.get('requestedServices')
                      ?.value as RequestedServicesFormFieldValue
                  ).isDraft
                  ? (
                      field.form?.get('requestedServices')
                        ?.value as MultipleRequestedServicesFormFieldValue
                    ).draftValues.find((i) => [7, 8].includes(i))
                  : (
                      field.form?.get('requestedServices')
                        ?.value as MultipleRequestedServicesFormFieldValue
                    ).values.find((i) => [7, 8].includes(i))
                : Object.entries(
                    field.form?.get('requestedServices')?.value ?? {},
                  ).find(
                    ([key, value]) =>
                      (['7', '8'].includes(key) ||
                        [7, 8].includes(key as any)) &&
                      value == true,
                  )) ||
              !(forStaffView
                ? (
                    field.form?.get('requestedServices')
                      ?.value as RequestedServicesFormFieldValue
                  ).isDraft
                  ? (
                      field.form?.get('requestedServices')
                        ?.value as MultipleRequestedServicesFormFieldValue
                    ).draftValues.find((i) => [2].includes(i))
                  : (
                      field.form?.get('requestedServices')
                        ?.value as MultipleRequestedServicesFormFieldValue
                    ).values.find((i) => [2].includes(i))
                : Object.entries(
                    field.form?.get('requestedServices')?.value ?? {},
                  ).find(
                    ([key, value]) =>
                      (['2'].includes(key) || [2].includes(key as any)) &&
                      value == true,
                  )),
          },
        },
        {
          key: 'requestedGradingCompany',
          type: 'select',
          props: {
            label: 'Requested Grading Company',
            options: ['PSA', 'SGC', 'BGS'].map((label) => ({
              value: label,
              label,
            })),
            required: true,
          },
          expressions: {
            hide: (field) =>
              !field.form?.get('tradingCardType')?.value ||
              !(forStaffView
                ? (
                    field.form?.get('requestedServices')
                      ?.value as RequestedServicesFormFieldValue
                  ).isDraft
                  ? (
                      field.form?.get('requestedServices')
                        ?.value as MultipleRequestedServicesFormFieldValue
                    ).draftValues.find((i) => [7, 8].includes(i))
                  : (
                      field.form?.get('requestedServices')
                        ?.value as MultipleRequestedServicesFormFieldValue
                    ).values.find((i) => [7, 8].includes(i))
                : Object.entries(
                    field.form?.get('requestedServices')?.value ?? {},
                  ).find(
                    ([key, value]) =>
                      (['7', '8'].includes(key) ||
                        [7, 8].includes(key as any)) &&
                      value == true,
                  )),
          },
        },
        {
          key: 'notes',
          type: 'textarea',
          props: {
            label: 'Notes',
            rows: 2,
            placeholder: 'Include any other important details.',
          },
          expressions: {
            hide: (field) => !field.form?.get('tradingCardType')?.value,
          },
        },

        // staff only
        {
          template: '<p><strong>Staff Determined Details</strong></p>',
          expressions: { hide: () => !forStaffView },
        },
        {
          key: 'cardGrade',
          type: 'input',
          props: {
            label: 'Grade',
          },
          expressions: {
            hide: (field) =>
              !forStaffView ||
              !(field.form?.get('requestedServices')?.value).values?.includes(
                2,
              ), // GSA offered service id
          },
        },
        {
          key: 'value',
          type: 'number',
          props: {
            label: 'Value',
          },
          expressions: { hide: () => !forStaffView },
        },
        {
          key: 'customerFacingNotes',
          type: 'textarea',
          props: {
            label: 'Customer Facing Notes',
            rows: 10,
          },
          expressions: { hide: () => !forStaffView },
        },
      ];
      observer.next(formFields);
    });
  });
};
