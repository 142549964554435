import { createAction, props } from '@ngrx/store';
import { User } from '@prisma/client';

const domainSlug = '[AUTH]';

const loginCustomer = createAction(
  `${domainSlug} Login Customer`,
  props<{ email: string; password: string }>(),
);

const loginStaff = createAction(
  `${domainSlug} Login Staff`,
  props<{ email: string; password: string }>(),
);

const registerCustomer = createAction(
  `${domainSlug} Register Customer`,
  props<{
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    username: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
  }>(),
);

const updateProfile = createAction(
  `${domainSlug} Update Profile`,
  props<{
    firstName?: string;
    lastName?: string;
    email?: string;
    receiveEmailNotifications?: boolean;
  }>(),
);

const setUser = createAction(`${domainSlug} Set User`, props<{ user: User }>());
const setError = createAction(
  `${domainSlug} Set Error`,
  props<{ message?: string }>(),
);
const clearError = createAction(`${domainSlug} Clear Error`);

const setOriginalPath = createAction(
  `${domainSlug} Set Original Path`,
  props<{ path: string }>(),
);

const clearOriginalPath = createAction(`${domainSlug} Set Original Path`);

const setPaymentMethodStatus = createAction(
  `${domainSlug} Set User Payment Method Status`,
  props<{ setup: boolean }>(),
);
const checkPaymentMethodStatus = createAction(
  `${domainSlug} Check User Payment Method Status`,
);

const logout = createAction(`${domainSlug} Logout`);
export const authActions = {
  loginCustomer,
  loginStaff,
  registerCustomer,
  setOriginalPath,
  clearOriginalPath,
  updateProfile,
  setUser,
  setError,
  clearError,
  logout,
  checkPaymentMethodStatus,
  setPaymentMethodStatus,
};
