<aa-view-bar title="Settings" [largeTitle]="true"></aa-view-bar>

<div class="customer-view-container">
  <div class="customer-view-sections">
    <div class="section">
      <div class="section-header">
        <h2>User</h2>
        <button mat-icon-button (click)="openEditForm()">
          <mat-icon>edit</mat-icon>
        </button>
      </div>

      <p>Manage basic account information and login</p>

      <div class="quick-details">
        <div class="settings-item">
          <p class="label">Name:</p>

          <p class="value">
            {{
              (user$ | async)?.customerProfile?.firstName +
                ' ' +
                (user$ | async)?.customerProfile?.lastName
            }}
          </p>
        </div>
        <div class="settings-item">
          <p class="label">Email:</p>

          <p class="value">{{ (user$ | async)?.email ?? 'N/A' }}</p>
        </div>
        <div class="settings-item">
          <p class="label">Address:</p>

          <p>
            {{ (user$ | async)?.customerProfile?.addressLine1 }}

            {{
              (user$ | async)?.customerProfile?.addressLine2
                ? ', ' + (user$ | async)?.customerProfile?.addressLine2
                : ''
            }}

            <!-- <p> -->
            {{ (user$ | async)?.customerProfile?.city }}
            {{ (user$ | async)?.customerProfile?.state }},
            {{ (user$ | async)?.customerProfile?.zipCode }}
            <!-- </p> -->
            <!-- <p> -->
            {{ (user$ | async)?.customerProfile?.country }}
            <!-- </p> -->
          </p>
        </div>
      </div>

      @if (submittedPasswordReset$ | async) {
        <p>
          If an account with that email exists, it will receive a reset password
          link shortly.
        </p>
      } @else {
        <button
          id="reset-password-button"
          mat-flat-button
          color="primary"
          (click)="sendPasswordResetEmail()">
          Send Reset Password Link
        </button>
      }
    </div>
    <div class="section">
      <h2>Payments</h2>
      <p>Payments are managed by Stripe</p>
      <button
        id="stripe-button"
        mat-flat-button
        color="primary"
        (click)="navigateToStripeCustomerPortal()">
        Stripe Customer Portal
      </button>
    </div>
  </div>
</div>

<div class="customer=view-actions"></div>
