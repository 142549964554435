import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'aa-next-steps',
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
  templateUrl: './next-steps.component.html',
  styleUrl: './next-steps.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NextStepsComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { orderId: number },
    public dialogRef?: MatDialogRef<NextStepsComponent>,
  ) {}

  close() {
    this.dialogRef?.close();
  }

  openPrintOrderLabel() {
    const url = `${window.location.origin}/order-slip/${this.data.orderId}`;
    console.log(url, window.location);
    const newWindow = window.open(url);
    setTimeout(() => {
      if (newWindow) newWindow.print();
    }, 600);
  }
}
