import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { map, Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { OrderResourceTypeMappings } from '@aa/nest/resource';
import { Store } from '@ngrx/store';
import { CoreAppState, orderActions, selectOrderState } from '@aa/angular/core';

@Component({
  selector: 'aa-order-slip-view',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './order-slip-view.component.html',
  styleUrl: './order-slip-view.component.scss',
})
export class OrderSlipViewComponent {
  orderId$: Observable<number>;
  order$: Observable<
    OrderResourceTypeMappings['resourceWithRelationsT'] | undefined | null
  >;

  constructor(
    private readonly store: Store<CoreAppState>,
    private readonly route: ActivatedRoute,
  ) {
    this.orderId$ = this.route.paramMap.pipe(
      map((paramMap) => parseInt(paramMap.get('orderId')!)),
      takeUntilDestroyed(),
    );
    this.order$ = this.store
      .select((s) => selectOrderState(s).current)
      .pipe(takeUntilDestroyed());

    this.orderId$.subscribe((orderId) => {
      this.store.dispatch(
        orderActions.loadItem({
          id: orderId,
          include: {
            orderCards: true,
          },
        }),
      );
    });
  }
}
