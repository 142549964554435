<div class="container">
  @if ((hideLayout$ | async) !== true) {
    <aa-app-bar (showInstallPrompt)="showInstallPrompt()"></aa-app-bar>
  }
  <div
    class="v-scrollable"
    [ngClass]="{ 'content-holder': !(hideLayout$ | async) }">
    <ng-content></ng-content>

    @if ((hideLayout$ | async) !== true) {
      @if ((showHelpImage$ | async) == true) {
        <aa-fab-menu
          menuIcon="question_mark"
          [menuItems]="menuItemsWithHelpImage">
        </aa-fab-menu>
      } @else {
        <aa-fab-menu menuIcon="question_mark" [menuItems]="menuItems">
        </aa-fab-menu>
      }
    }
  </div>
</div>

<pwa-install
  #pwaInstallPrompt
  disable-screenshots="true"
  disable-screenshots-apple="true"
  disable-screenshots-chrome="true"
  disable-install-description="true"
  description="Install the All Angles app for easy access and a more native experience."
  manifest-url="/manifest.webmanifest"></pwa-install>
