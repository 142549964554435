<div class="card" [routerLink]="isDraft() ? null : 'order-review/' + order.id">
  <div class="count">
    <p>{{ order.orderCards?.length ?? order._count?.orderCards ?? 0 }}</p>
    <p>Card(s)</p>
  </div>
  <div class="details">
    @if (!isDraft() && !asHeader) {
      <p class="order-id">Order #O_{{ order.id }}</p>
    }
    <p class="order-name">{{ order.name }}</p>
    @if (!isDraft()) {
      <p
        class="order-status"
        [ngStyle]="{
          backgroundColor: order.orderStatus?.hexColor,
          color: computeTextColorForBackground(
            order.orderStatus?.hexColor ?? '#ffffff'
          )
        }">
        {{ order.orderStatus?.name }}
      </p>
    }

    @if (order!.orderStatusId <= 2 && !isDraft()) {
      <button
        mat-flat-button
        color="primary"
        id="shipping-button"
        (click)="$event.stopImmediatePropagation(); showShippingInstructions()">
        Shipping Instructions
        <mat-icon iconPositionEnd>open_in_new</mat-icon>
      </button>
      <!-- <aa-next-steps></aa-next-steps> -->
    }

    @if (order.showChangesToCustomer && order.changesApproved == null) {
      <p class="approval-needed-chip">Approval Needed</p>
    }

    <p class="timestamp">Created on {{ formatDate(order.createdAt) }}</p>
    <p class="timestamp">Updated on {{ formatDate(order.updatedAt) }}</p>
  </div>
  <div class="actions">
    @if (isDraft()) {
      <button mat-icon-button [routerLink]="'draft-editor/' + order.id">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button (click)="promptDelete()">
        <mat-icon>delete</mat-icon>
      </button>
    } @else if (!asHeader) {
      <button
        mat-icon-button
        [routerLink]="'order-review/' + order.id"
        class="mr-1"
        matTooltipPosition="left"
        [matTooltip]="
          order.showChangesToCustomer && order.changesApproved == null
            ? ''
            : 'Review Submission'
        ">
        @if (order.showChangesToCustomer && order.changesApproved == null) {
          <div class="notification-dot"></div>
        }

        <mat-icon iconPositionEnd>receipt_long</mat-icon>
      </button>
    }
  </div>
</div>
