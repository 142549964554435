import {
  BaseResourceListViewComponent,
  baseResourceListViewImports,
} from '../base-resource-list-view/base-resource-list-view.component';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  computeTextColorForBackground,
  formatAddress,
  formatDateTime,
} from '@aa/ts/common';
import { DataTableColumnConfig } from '../../components/data-table/data-table.component';
import { ORDER_STATUS } from '@aa/nest/resource/objects';
import { orderActions } from '../../state/order/order.actions';
import { OrderFormModalComponent } from '../../forms/order-form-modal/order-form-modal.component';
import { OrderResourceTypeMappings } from '@aa/nest/resource';
import { selectOrderState } from '../../state/order/order.reducer';

// @aa:gen-ignore

@Component({
  selector: 'aa-orders-list-view',
  standalone: true,
  imports: [...baseResourceListViewImports],
  templateUrl:
    '../../../../../core/src/lib/views/base-resource-list-view/base-resource-list-view.component.html',
  styleUrl:
    '../../../../../core/src/lib/views/base-resource-list-view/base-resource-list-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersListViewComponent
  extends BaseResourceListViewComponent<OrderResourceTypeMappings>
  implements OnInit
{
  title = 'Orders';
  columns = [
    {
      key: 'id',
      label: 'ID',
      // accessor: (row) => `O_${row.id}`,
      getRouterLinkValue: (row) => `${row.id}`,
      isSortable: true,
    },
    // {
    //   key: 'name',
    //   label: 'Name',
    // },
    {
      key: 'customerId',
      label: 'Customer',
      valueType: 'preview-card',
      accessor: (row) => row.customerProfile?.username,
      previewAttributeAccessor: (row) => [
        {
          label: 'Name',
          value: `${row.customerProfile?.firstName} ${row.customerProfile?.lastName}`,
        },
        {
          label: 'Email',
          value: row.customerProfile?.user?.email ?? '',
        },
        {
          label: 'Address',
          value: formatAddress({
            line1: row.customerProfile!.addressLine1,
            line2: row.customerProfile!.addressLine2,
            city: row.customerProfile!.city,
            state: row.customerProfile!.state,
            zip: row.customerProfile!.zipCode,
            country: row.customerProfile!.country,
          }),
        },
        {
          label: 'Stripe Customer ID',
          value: `${row.customerProfile?.stripeCustomerId}`,
        },
        {
          label: 'Notes',
          value: row.customerProfile?.notes ?? '',
        },
      ],
      isSortable: true,
    },
    // {
    //   key: 'requestedServices',
    //   label: 'Requested Services',
    //   accessor: (row) =>
    //     row.orderServiceLinkages
    //       ?.map((li) => li.offeredService?.name ?? '')
    //       .join(', '),
    // },
    {
      key: 'orderSourceId',
      label: 'Source',
      accessor: (row) => row.orderSource?.name,
      isSortable: true,
      getBackgroundColor: (row) => row.orderSource?.hexColor ?? '#ffffff',
      getTextColor: (row) =>
        computeTextColorForBackground(row.orderSource?.hexColor ?? '#ffffff'),
    },
    {
      key: 'orderStatusId',
      label: 'Status',
      accessor: (row) => row.orderStatus?.name,
      isSortable: true,
      getBackgroundColor: (row) => row.orderStatus?.hexColor ?? '#ffffff',
      getTextColor: (row) =>
        computeTextColorForBackground(row.orderStatus?.hexColor ?? '#ffffff'),
    },
    {
      key: 'cardCount',
      label: 'Card Count',
      accessor: (row) => row.orderCards?.length ?? 0,
    },
    {
      key: 'submittedAt',
      label: 'Submitted At',
      accessor: (row) => formatDateTime(row.submittedAt),
    },
  ] as DataTableColumnConfig<
    OrderResourceTypeMappings['resourceWithRelationsT']
  >[];

  stateSelector = selectOrderState;
  loadAction = orderActions.loadItems({
    query: {
      where: {
        orderStatusId: {
          not: ORDER_STATUS.DRAFT,
        },
      },
      include: {
        orderStatus: true,
        orderSource: true,
        orderServiceLinkages: {
          include: {
            offeredService: true,
          },
        },
        orderCards: true,
        customerProfile: {
          include: {
            user: true,
          },
        },
      },
      pageSize: this.initialPageSize,
    },
  });
  actions = orderActions;

  createFormModalTypeGetter = () => OrderFormModalComponent;
  updateFormModalTypeGetter = () => undefined;

  override async ngOnInit() {
    await super.ngOnInit();
    this.tableConfig = { ...this.tableConfig, onAdd: undefined };
    this.adjustQuery({
      orderBy: {
        createdAt: 'desc',
      },
    });
  }

  override adjustModelBeforeEdit = (
    item: OrderResourceTypeMappings['resourceWithRelationsT'],
  ) => ({
    ...item,
    requestedServices: item.orderServiceLinkages?.map(
      (li) => li.offeredServiceId,
    ),
  });
}
