import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BaseResourceEffects, NoAction } from '../base-resource.effects';
import { catchError, forkJoin, map, mergeMap, of, switchMap, take } from 'rxjs';
import { CoreAppState } from '../core-app.state';
import { Injectable } from '@angular/core';
import { offeredServiceActions } from '../offered-service/offered-service.actions';
import { orderCardActions } from './order-card.actions';
import { OrderCardAttributeService } from '../../services/order-card-attribute.service';
import { OrderCardAttributeTypeService } from '../../services/order-card-attribute-type.service';
import { OrderCardResourceTypeMappings } from '@aa/nest/resource';
import { OrderCardService } from '../../services/order-card.service';
import { selectOrderCardState } from './order-card.reducer';
import { Store } from '@ngrx/store';

// @aa:gen-ignore

@Injectable()
export class OrderCardEffects extends BaseResourceEffects<OrderCardResourceTypeMappings> {
  createCardWithAttributes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(orderCardActions.createCardWithAttributes),
      mergeMap((payload) =>
        this.store
          .select((s) => this.stateSelector(s).currentQuery)
          .pipe(
            take(1),
            switchMap((currentQuery) =>
              this.service.create(payload.cardData).pipe(
                switchMap((res) => {
                  const observables = [];
                  for (const attribute of payload.attributes) {
                    if (attribute.value) {
                      observables.push(
                        this.orderCardAttributeTypeService
                          .getMany({
                            where: {
                              fieldName: attribute.fieldName,
                            },
                          })
                          .pipe(
                            switchMap((attributeTypesRes) =>
                              attributeTypesRes.data.length > 0
                                ? this.orderCardAttributeService.create({
                                    orderCardId: res.id,
                                    orderCardAttributeTypeId:
                                      attributeTypesRes.data[0].id,
                                    value: `${attribute.value}`,
                                  })
                                : of(NoAction()),
                            ),
                            catchError((err) =>
                              of(
                                this.itemActions.setError({
                                  error:
                                    err.error?.message?.toString() ??
                                    err.message?.toString() ??
                                    err.toString(),
                                }),
                              ),
                            ),
                          ),
                      );
                    }
                  }

                  return forkJoin(observables).pipe(
                    map(
                      () =>
                        offeredServiceActions.handleOrderCardLinkages({
                          data: {
                            orderCardId: res.id,
                            linkages:
                              payload.offeredServicesIds?.map((id) => ({
                                offeredServiceId: id,
                              })) ?? [],
                          },
                          chainedAction: orderCardActions.reloadItems({}),
                        }),

                      // this.itemActions.loadItems({
                      //   query: currentQuery as any,
                      // }),
                    ),
                  );
                }),
                catchError((err) =>
                  of(
                    this.itemActions.setError({
                      error:
                        err.error?.message?.toString() ??
                        err.message?.toString() ??
                        err.toString(),
                    }),
                  ),
                ),
              ),
            ),
          ),
      ),
    ),
  );

  updatedCardWithAttributes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(orderCardActions.updateCardWithAttributes),
      mergeMap((payload) =>
        this.store
          .select((s) => this.stateSelector(s).currentQuery)
          .pipe(
            take(1),
            switchMap((currentQuery) =>
              this.service.update(payload.id, payload.cardUpdates).pipe(
                switchMap((res) => {
                  const observables = [];
                  for (const attribute of payload.attributes) {
                    if (
                      attribute.orderCardId &&
                      attribute.orderCardAttributeTypeId
                    ) {
                      observables.push(
                        attribute.value
                          ? this.orderCardAttributeService.update(
                              {
                                orderCardId: attribute.orderCardId,
                                orderCardAttributeTypeId:
                                  attribute.orderCardAttributeTypeId,
                              },
                              {
                                value: attribute.value,
                              },
                            )
                          : this.orderCardAttributeService.delete({
                              orderCardId: attribute.orderCardId,
                              orderCardAttributeTypeId:
                                attribute.orderCardAttributeTypeId,
                            }),
                      );
                    } else if (attribute.value) {
                      observables.push(
                        this.orderCardAttributeTypeService
                          .getMany({
                            where: {
                              fieldName: attribute.fieldName,
                            },
                          })
                          .pipe(
                            switchMap((attributeTypesRes) =>
                              attributeTypesRes.data.length > 0
                                ? this.orderCardAttributeService.create({
                                    orderCardId: res.id,
                                    orderCardAttributeTypeId:
                                      attributeTypesRes.data[0].id,
                                    value: `${attribute.value}`,
                                  })
                                : of(NoAction()),
                            ),
                            catchError((err) =>
                              of(
                                this.itemActions.setError({
                                  error:
                                    err.error?.message?.toString() ??
                                    err.message?.toString() ??
                                    err.toString(),
                                }),
                              ),
                            ),
                          ),
                      );
                    }
                  }

                  return forkJoin(observables).pipe(
                    map(
                      // () =>
                      //   offeredServiceActions.handleOrderCardLinkages({
                      //     data: {
                      //       orderCardId: payload.id,
                      //       linkages: [
                      //         ...(payload.offeredServicesIds?.map((id) => ({
                      //           offeredServiceId: id,
                      //         })) ?? []),
                      //         ...(payload.draftOfferedServicesIds?.map(
                      //           (id) => ({
                      //             offeredServiceId: id,
                      //             isDraft: true,
                      //           }),
                      //         ) ?? []),
                      //       ],
                      //     },
                      //   }),

                      () =>
                        offeredServiceActions.handleOrderCardLinkages({
                          data: {
                            orderCardId: payload.id,
                            linkages:
                              payload.offeredServicesIds?.map((id) => ({
                                offeredServiceId: id,
                              })) ?? [],
                          },
                          chainedAction:
                            offeredServiceActions.handleOrderCardLinkages({
                              data: {
                                orderCardId: payload.id,
                                linkages:
                                  payload.draftOfferedServicesIds?.map(
                                    (id) => ({
                                      offeredServiceId: id,
                                      isDraft: true,
                                    }),
                                  ) ?? [],
                              },
                              chainedAction: orderCardActions.reloadItems({}),
                              forDraft: true,
                            }) as any,
                        }),
                    ),
                  );
                }),
                catchError((err) =>
                  of(
                    this.itemActions.setError({
                      error:
                        err.error?.message?.toString() ??
                        err.message?.toString() ??
                        err.toString(),
                    }),
                  ),
                ),
              ),
            ),
          ),
      ),
    ),
  );

  constructor(
    protected override readonly actions$: Actions,
    protected override readonly store: Store<CoreAppState>,
    protected override readonly service: OrderCardService,
    private readonly orderCardAttributeService: OrderCardAttributeService,
    private readonly orderCardAttributeTypeService: OrderCardAttributeTypeService,
  ) {
    super(actions$, store, service, orderCardActions, selectOrderCardState);
  }
}
