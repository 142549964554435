import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerAppState } from '../../state/index.reducers';
import { BehaviorSubject, filter, firstValueFrom, map, Observable } from 'rxjs';
import { PaymentService, selectAuth } from '@aa/angular/core';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ViewBarComponent } from '../../components/view-bar/view-bar.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { NextStepsComponent } from '../../components/next-steps/next-steps.component';

@Component({
  selector: 'aa-order-payment-view',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    ViewBarComponent,
    NextStepsComponent,
  ],
  templateUrl: './order-payment-view.component.html',
  styleUrl: './order-payment-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPaymentViewComponent {
  loading$ = new BehaviorSubject(true);

  isSuccess$: Observable<boolean>;
  sessionId$: Observable<string>;
  orderId$: Observable<number | undefined>;

  constructor(
    private readonly store: Store<CustomerAppState>,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly paymentService: PaymentService,
  ) {
    setTimeout(() => {
      this.loading$.next(false);
    }, 2000);

    this.orderId$ = this.route.paramMap.pipe(
      map((paramMap) => parseInt(paramMap.get('orderId')!)),
      takeUntilDestroyed(),
    );

    this.isSuccess$ = this.route.queryParams.pipe(
      map((queryParams) => queryParams['success'] == 'true'),
      takeUntilDestroyed(),
    );
    this.sessionId$ = this.route.queryParams.pipe(
      map((queryParams) => queryParams['session_id']),
      takeUntilDestroyed(),
    );

    this.isSuccess$.subscribe(async (success) => {
      if (success) {
        const orderId = await firstValueFrom(
          this.orderId$.pipe(filter((orderId) => !!orderId)),
        );
        this.router.navigate(['order-review', orderId], {
          queryParams: {
            showShippingInfo: true,
          },
        });
      }
    });
    this.sessionId$.subscribe(async (sessionId) => {
      const userId = await firstValueFrom(
        this.store.select((s) => selectAuth(s).user?.id),
      );
      // TODO: remove if staying with payments setup from beginning
      // if (userId && sessionId) {
      //   await firstValueFrom(
      //     this.paymentService.associateCustomerFromSession(userId, sessionId),
      //   );
      // }
    });
  }
}
