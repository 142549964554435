<div class="container">
  @if (isStaffApp$ | async) {
    <h2>
      Invoice #I_{{ invoice?.id }} -
      {{ formatDateTime(invoice?.createdAt) }}
    </h2>
  }

  <h2>Items</h2>

  <table>
    @for (item of consolidatedInvoiceItems; track $index; let index = $index) {
      @if ((expandedItemIndexes$ | async)?.includes($index)) {
        @for (
          subItem of item.includedItems;
          track subItem.id;
          let subItemIndex = $index
        ) {
          <tr
            class="invoice-item"
            [ngClass]="{
              'pending-refund':
                getInvoiceItemPendingRefund(subItem) && !showAsCustomerView,
              refunded: getInvoiceItemCompletedRefund(subItem)
            }">
            <td>
              <p class="title">
                {{ subItem.title }}
              </p>
              @if (subItem.orderCardServiceLinkages?.at(0)?.orderCard?.label) {
                <p>
                  {{
                    subItem.orderCardServiceLinkages?.at(0)?.orderCard?.label
                  }}
                </p>
              }
              @if (subItem.description) {
                <p>{{ subItem.description }}</p>
              }

              @if (
                getInvoiceItemPendingRefund(subItem) && !showAsCustomerView
              ) {
                <p class="refund-date">
                  Pending Refund as of
                  {{
                    formatDateTime(
                      getInvoiceItemPendingRefund(subItem)!.createdAt
                    )
                  }}
                </p>
              } @else if (getInvoiceItemCompletedRefund(subItem)) {
                <p class="refund-date">
                  Refunded on
                  {{
                    formatDateTime(
                      getInvoiceItemCompletedRefund(subItem)!.createdAt
                    )
                  }}
                </p>
              }
            </td>
            <td>
              {{ subItem.quantity }}
            </td>
            <td class="nowrap">
              x {{ formatMoney($any(subItem.costPerUnit) ?? 0) }}
            </td>

            <td class="actions">
              @if (subItemIndex == 0) {
                <button
                  id="expand-less-item-button"
                  matTooltip="Collapse Included Items"
                  mat-icon-button
                  (click)="toggleItemExpansion(index)">
                  <mat-icon>expand_less</mat-icon>
                </button>
              }
              @if (!showAsCustomerView) {
                <button
                  id="edit-item-button"
                  matTooltip="Edit"
                  mat-icon-button
                  (click)="openInvoiceItemForm(subItem.id, hasValidPayment())">
                  <mat-icon>edit</mat-icon>
                </button>
              }
              @if (
                !showAsCustomerView && !isCustomerDraft() && !hasValidPayment()
              ) {
                <button
                  id="delete-item-button"
                  matTooltip="Delete"
                  mat-icon-button
                  (click)="deleteInvoiceItem(subItem.id)">
                  <mat-icon>delete</mat-icon>
                </button>
              } @else if (!showAsCustomerView && isFinalized()) {
                <!-- <button
                  id="refumd-item-button"
                  matTooltip="Refund"
                  mat-icon-button
                  (click)="openInvoiceRefundForm(subItem.id)">
                  <mat-icon>assignment_return</mat-icon>
                </button> -->
              }
            </td>
          </tr>
        }
      } @else {
        <tr
          class="invoice-item"
          [ngClass]="{
            'pending-refund':
              getInvoiceItemPendingRefund(item.includedItems[0]) &&
              !showAsCustomerView,
            refunded: getInvoiceItemCompletedRefund(item.includedItems[0])
          }">
          <td>
            <p class="title">
              {{ item.title }}
            </p>
            @if (
              item.includedItems &&
              item.includedItems.length == 1 &&
              item.includedItems[0].orderCardServiceLinkages?.at(0)?.orderCard
                ?.label
            ) {
              <p>
                {{
                  item.includedItems[0].orderCardServiceLinkages?.at(0)
                    ?.orderCard?.label
                }}
              </p>
            }

            @if (item.description) {
              <p>{{ item.description }}</p>
            }

            <!-- @if (
              item.includedItems &&
              item.includedItems.length == 1 &&
              item.includedItems[0]?.orderCardServiceLinkages?.[0]?.orderCardId
            ) {
              <p>
                C-{{
                  item.includedItems[0]?.orderCardServiceLinkages?.[0]
                    ?.orderCardId
                }}
              </p>
            } -->

            @if (
              getInvoiceItemPendingRefund(item.includedItems[0]) &&
              !showAsCustomerView
            ) {
              <p class="refund-date">
                Pending Refund as of
                {{
                  formatDateTime(
                    getInvoiceItemPendingRefund(item.includedItems[0])!
                      .createdAt
                  )
                }}
              </p>
            } @else if (getInvoiceItemCompletedRefund(item.includedItems[0])) {
              <p class="refund-date">
                Refunded on
                {{
                  formatDateTime(
                    getInvoiceItemCompletedRefund(item.includedItems[0])!
                      .createdAt
                  )
                }}
              </p>
            }
          </td>
          <td>
            {{ item.quantity }}
          </td>
          <td class="nowrap">
            x {{ formatMoney($any(item.costPerUnit) ?? 0) }}
          </td>

          <td class="actions">
            @if (item.includedItems.length > 1) {
              <button
                id="edit-item-button"
                matTooltip="Expand Included Items"
                mat-icon-button
                (click)="toggleItemExpansion($index)">
                <mat-icon>expand_more</mat-icon>
              </button>
            } @else {
              @if (!showAsCustomerView) {
                <button
                  id="edit-item-button"
                  matTooltip="Edit"
                  mat-icon-button
                  (click)="
                    openInvoiceItemForm(
                      item.includedItems[0].id,
                      hasValidPayment()
                    )
                  ">
                  <mat-icon>edit</mat-icon>
                </button>
              }
              @if (
                !showAsCustomerView && !isCustomerDraft() && !hasValidPayment()
              ) {
                <button
                  id="delete-item-button"
                  matTooltip="Delete"
                  mat-icon-button
                  (click)="deleteInvoiceItem(item.includedItems[0].id)">
                  <mat-icon>delete</mat-icon>
                </button>
              } @else if (!showAsCustomerView && isFinalized()) {
                <!-- <button
                  id="refund-item-button"
                  matTooltip="Refund"
                  mat-icon-button
                  (click)="openInvoiceRefundForm(item.includedItems[0].id)">
                  <mat-icon>currency_exchange</mat-icon>
                </button> -->
              }
            }
          </td>
        </tr>
      }
    }
  </table>

  <!-- <div class="invoice-items">
    @for (item of consolidatedInvoiceItems ?? []; track $index) {
      <div class="invoice-item">
        <p class="title">
          {{ item.title }} - {{ item.quantity }} x ${{ item.costPerUnit }}
        </p>
        @if (item.description) {
          <p>{{ item.description }}</p>
        }
      </div>
    }
  </div> -->

  @if (!showAsCustomerView && !isCustomerDraft() && !hasValidPayment()) {
    <button id="add-item-button" mat-mini-fab (click)="openInvoiceItemForm()">
      <mat-icon>add</mat-icon>
    </button>
  }

  @if ((invoice?.invoicePayments ?? []).length > 0) {
    <h3>Payments</h3>
    @for (payment of invoice.invoicePayments; track payment.id) {
      <div class="payment card">
        <div class="header">
          <p class="title">
            Created at {{ formatDateTime(payment.createdAt) }}
            <br />
            Updated at {{ formatDateTime(payment.updatedAt) }}
          </p>
          <!-- <div class="actions">
            @if (
              !showAsCustomerView && invoicePaymentIsFinalized($any(payment))
            ) {
              <button mat-icon-button matTooltip="Refund">
                <mat-icon>currency_exchange</mat-icon>
              </button>
            }
          </div> -->
        </div>
        <p
          class="status"
          [ngStyle]="getInvoicePaymentStatusCSS(payment.status)">
          {{ getInvoicePaymentStatusLabel(payment.status) }}
        </p>
        <p>Amount: {{ formatMoney($any(payment.amount)) }}</p>
      </div>
    }
  }

  @if ((invoice?.refundItems ?? []).length > 0) {
    <h3>Refunds</h3>
    @for (refund of invoice.refundItems; track refund.id) {
      <div class="refund card">
        <div class="header">
          <div class="details">
            <p class="title">{{ formatDateTime(refund.createdAt) }}</p>
            <p class="subtitle">{{ refund.invoiceItem?.title }}</p>
          </div>
          <div class="actions">
            @if (!refundItemIsFinalized($any(refund)) && !showAsCustomerView) {
              <button
                mat-icon-button
                matTooltip="Cancel Refund"
                (click)="cancelRefundItem(refund.id)">
                <mat-icon>delete</mat-icon>
              </button>
            }
          </div>
        </div>
        <p class="status" [ngStyle]="getRefundItemStatusCSS(refund.status)">
          {{ getRefundItemStatusLabel(refund.status) }}
        </p>
        <p>Amount: {{ formatMoney($any(refund.amount)) }}</p>
      </div>
    }
  }

  <h3>Total: {{ formatMoney($any(invoice?.amount) ?? 0) }}</h3>

  @if (!showAsCustomerView) {
    <div class="actions">
      @if (!isCustomerDraft() && !isFinalized() && canCancel()) {
        <button mat-flat-button id="cancel-button" (click)="cancel()">
          Cancel
        </button>
      }
      @if (!isCustomerDraft() && !isFinalized()) {
        <button mat-flat-button id="finalize-button" (click)="finalize()">
          Finalize
        </button>
      }
    </div>
  }
</div>
