<div class="container">
  <div class="header">
    <h2>Now you're ready to pack and ship your cards to All Angles!</h2>

    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <ol class="next-steps">
    <li>Print the order slip by pressing the button below.</li>

    <button
      id="print-button"
      mat-flat-button
      color="primary"
      (click)="openPrintOrderLabel()">
      Print Order Slip
    </button>

    <p>
      The order slip is important for us to match up your package with your
      order.
    </p>

    <li>Pack your cards along with the order slip into a box for shipping.</li>
    <p>
      Be sure to pack you cards securely to avoid any damage that could incur in
      transit.
    </p>

    <li>Ship your cards to us.</li>
    <p>
      You can use any shipping service you prefer, but domestically we recommend
      using USPS with tracking.

      <br />
      <br />

      Once you're ready to ship, please mail your items to the following
      address:

      <br />
      <br />

      All Angles<br />
      829 Waukegan Road<br />
      Deerfield, IL 60015
    </p>

    <li>Let us handle the rest!</li>
    <p>
      We will update your All Angles order once your cards are received, and
      provide updates on the process through the All Angles app and website.

      <br />
      <br />

      If you have specified minimum desired grades, and our AI analysis does not
      believe the card(s) will meet your requirements, the card(s) will be
      returned to the verified address you provided in your account setup.
      <br />
      <br />
      Ease of grading, multiple grading options, and improved clarity regarding
      the expected grade, that's the AllAngles advantage!
    </p>
  </ol>
</div>
