<h2>Cards</h2>
<div class="cards">
  @for (card of cards$ | async; track card.id) {
    <div class="card p-0">
      <div class="images">
        <img [src]="card.frontImageURL" />
        <img [src]="card.backImageURL" />
      </div>
      <div class="details">
        <div class="services">
          <!-- <h2>C-{{ card.id }}</h2> -->

          <!-- <h2>{{ card.label }} (#C_{{ card.id }})</h2> -->
          <h2>{{ card.label }}</h2>
          <h3>Services</h3>

          @for (
            serviceLinkage of (adjustedServicesSummary$ | async)?.[card.id] ??
              [];
            track $index
          ) {
            <tr
              [ngClass]="{
                deleted: serviceLinkage.deleted,
                added: serviceLinkage.added
              }">
              <p>
                @if (serviceLinkage.added) {
                  <mat-icon>add</mat-icon>
                } @else if (serviceLinkage.deleted) {
                  <mat-icon>remove</mat-icon>
                }
                {{ serviceLinkage?.offeredService?.name }}
              </p>
            </tr>
          }
        </div>

        @if (card.requestedGradingCompany || card.minimumGradeForSubmission) {
          <h3>Details</h3>
          <table>
            @if (card.requestedGradingCompany) {
              <tr>
                <td class="label">Grading Company</td>
                <td>
                  {{ card.requestedGradingCompany ?? '' }}
                </td>
              </tr>
            }
            @if (card.minimumGradeForSubmission) {
              <tr>
                <td class="label">Minimum Grade for Submission</td>
                <td>
                  {{ card.minimumGradeForSubmission ?? '' }}
                </td>
              </tr>
            }
          </table>
        }

        <h3>Attributes</h3>
        <table>
          @for (attribute of card.orderCardAttributes; track $index) {
            @if (
              attribute.value &&
              (!isString(attribute.value) || attribute.value.length > 0) &&
              attribute.value != '""' &&
              attribute.value != '"null"' &&
              attribute.value != 'null' &&
              attribute.value != 'undefined' &&
              attribute.value != '"undefined"'
            ) {
              <tr>
                <td class="label">
                  {{ attribute.orderCardAttributeType.name }}
                </td>
                <td>
                  {{
                    displayCardAttributeValue(
                      attribute.orderCardAttributeType?.fieldName,
                      attribute.value
                    )
                  }}
                </td>
              </tr>
            }
          }
        </table>

        @if (card.customerFacingNotes) {
          <h3>Staff Notes</h3>
          <p>{{ card.customerFacingNotes }}</p>
        }
      </div>
    </div>
  }
</div>
