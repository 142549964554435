<div class="mini-app-bar">
  <a href="/"><img src="assets/images/small-logo.png" /></a>
</div>
<div class="content-holder">
  <div class="doc-content">
    <h1 class="c9 c11"><span>Privacy Policy for All Angles Inc</span></h1>
    <h2 class="c3"><span class="c8">Introduction</span></h2>
    <p class="c5 c9">
      <span class="c2"
        >This Privacy Policy outlines the manner in which All Angles Inc ("the
        Company"), as the Data Controller, collects, uses, maintains, and
        discloses information collected from users (each, a "User") of its
        services. The practices described in this policy are specifically
        tailored to comply with the legal requirements of Lake County, Illinois,
        and are designed to protect the privacy and security of the User's
        information.</span
      >
    </p>
    <p class="c5 c9">
      <span class="c2"
        >The Company is committed to conducting its business in accordance with
        these principles in order to ensure that the confidentiality of personal
        information is protected and maintained. This policy applies to all
        information collected by the Company through its services and
        platforms.</span
      >
    </p>
    <h2 class="c3"><span class="c8">Information Collection</span></h2>
    <p class="c5 c9">
      <span
        >This Privacy Policy outlines the types of information that All Angles
        Inc ("We", "Us", "Our", or "Company") may collect from you ("User") or
        that you may provide when you visit any website, mobile application, or
        other digital service operated by All Angles Inc (collectively, the
        "Services"), and our practices for collecting, using, maintaining,
        protecting, and disclosing that information.</span
      ><sup><a href="#cmnt1" id="cmnt_ref1">[a]</a></sup>
    </p>
    <p class="c5 c9">
      <span class="c2">This policy applies to information we collect:</span>
    </p>
    <ul class="c10 lst-kix_list_1-0 start">
      <li class="c6 li-bullet-0"><span class="c2">On this Website.</span></li>
      <li class="c7 c5 li-bullet-0">
        <span class="c2"
          >In email, text, and other electronic messages between you and this
          Website.</span
        >
      </li>
      <li class="c7 c5 li-bullet-0">
        <span class="c2"
          >Through mobile and desktop applications you download from this
          Website, which provide dedicated non-browser-based interaction between
          you and this Website.</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c2"
          >When you interact with our advertising and applications on
          third-party websites and services, if those applications or
          advertising include links to this policy.</span
        >
      </li>
    </ul>
    <p class="c5 c9">
      <span class="c2">It does not apply to information collected by:</span>
    </p>
    <ul class="c10 lst-kix_list_2-0 start">
      <li class="c6 li-bullet-0">
        <span class="c2"
          >us offline or through any other means, including on any other website
          operated by All Angles Inc or any third party; or</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c2"
          >any third party, including through any application or content
          (including advertising) that may link to or be accessible from or on
          the Website.</span
        >
      </li>
    </ul>
    <h2 class="c3"><span class="c8">Use of Information</span></h2>
    <p class="c5 c9">
      <span class="c2"
        >This Privacy Policy outlines the manner in which All Angles Inc ("the
        Company") collects, uses, maintains, and discloses information collected
        from users (each, a "User") of its services. The Company is committed to
        protecting the privacy and security of the information it collects and
        to being transparent about the ways in which that information is
        used.</span
      >
    </p>
    <p class="c5 c9">
      <span
        >The Company may collect personal identification information from Users
        in a variety of ways, including, but not limited to, when Users visit
        our site, register on the site, place an order, subscribe to the
        newsletter, fill out a form, and in connection with other activities,
        services, features, or resources we make available. Users may be asked
        for, as appropriate, name, email address, mailing address, phone number,
        and credit card information. </span
      ><span
        >While certain portions of our Services may be accessed without
        providing personal identification information, the collection of such
        information may be necessary to use certain features or services. We
        will collect personal identification information from Users only with
        their consent or where we have another legal basis for processing such
        information under applicable law. Users should be aware that refusing to
        provide certain personal identification information may prevent them
        from accessing specific features or services.</span
      ><sup><a href="#cmnt2" id="cmnt_ref2">[b]</a></sup>
    </p>
    <p class="c5 c9">
      <span class="c2"
        >The Company uses the collected information for the following
        purposes:</span
      >
    </p>
    <ul class="c10 lst-kix_list_3-0 start">
      <li class="c6 li-bullet-0">
        <span class="c2">To improve customer service</span>
      </li>
      <li class="c7 c5 li-bullet-0">
        <span class="c2">To personalize user experience</span>
      </li>
      <li class="c7 c5 li-bullet-0">
        <span class="c2">To improve our site</span>
      </li>
      <li class="c7 c5 li-bullet-0">
        <span class="c2">To process payments</span>
      </li>
      <li class="c0 li-bullet-0">
        <span class="c2"
          >To send periodic emails regarding orders or other products and
          services</span
        >
      </li>
    </ul>
    <p class="c5 c9">
      <span class="c2"
        >By using the services provided by All Angles Inc, Users consent to the
        collection and use of their information as outlined in this Privacy
        Policy. The Company reserves the right to update this policy at any
        time. When we do, we will revise the updated date at the bottom of this
        page. We encourage Users to frequently check this page for any changes
        to stay informed about how we are helping to protect the personal
        information we collect. Your continued use of the service following the
        posting of changes to this policy will be deemed your acceptance of
        those changes.</span
      >
    </p>
    <h2 class="c3">
      <span class="c8">Information Sharing and Disclosure</span>
    </h2>
    <p class="c5 c9">
      <span class="c2"
        >All Angles Inc ("We", "Us", "Our") is committed to maintaining the
        privacy and protection of your personal identification information. This
        section outlines our practices regarding the sharing and disclosure of
        your personal identification information.</span
      >
    </p>
    <p class="c5 c9">
      <span class="c2"
        >We may share your personal identification information with third
        parties in the following situations:</span
      >
    </p>
    <ul class="c10 lst-kix_list_4-0 start">
      <li class="c6 li-bullet-0">
        <span class="c2"
          >With service providers who assist us in our business operations and
          have agreed to keep the information confidential. These providers
          include, but are not limited to, payment processing, delivery of
          goods, and marketing assistance.</span
        >
      </li>
      <li class="c7 c5 li-bullet-0">
        <span class="c2"
          >When required by law, such as in response to a subpoena or similar
          legal process.</span
        >
      </li>
      <li class="c5 c7 li-bullet-0">
        <span class="c2"
          >In the event of a merger, acquisition, or sale of all or a portion of
          our assets, you will be notified via email and/or a prominent notice
          on our site of any change in ownership or uses of your personal
          identification information, as well as any choices you may have
          regarding your personal identification information.</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c2"
          >To any other third party with your prior consent to do so.</span
        >
      </li>
    </ul>
    <p class="c5 c9">
      <span class="c2"
        >Your personal identification information may also be disclosed if we
        believe in good faith that such disclosure is necessary to:</span
      >
    </p>
    <ul class="c10 lst-kix_list_5-0 start">
      <li class="c6 li-bullet-0">
        <span class="c2">Protect our rights or property.</span>
      </li>
      <li class="c7 c5 li-bullet-0">
        <span class="c2"
          >Investigate fraud or respond to a government request.</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c2">Protect the safety of Users or the public.</span>
      </li>
    </ul>
    <p class="c5 c9">
      <span class="c2"
        >We will make every effort to ensure that any third party with whom we
        share your personal identification information will uphold and protect
        your information in accordance with this Privacy Policy and applicable
        laws.</span
      >
    </p>
    <h2 class="c3"><span class="c8">Data Security</span></h2>
    <p class="c5 c9">
      <span class="c2"
        >All Angles Inc ("the Company") is committed to protecting the security
        of your personal identification information. We implement a variety of
        security measures to maintain the safety of your personal information
        when you enter, submit, or access your personal information.</span
      >
    </p>
    <p class="c5 c9">
      <span class="c2"
        >These security measures include, but are not limited to, the use of
        secure server software (SSL) which encrypts all information you input
        before it is sent to us, and secure databases to store your personal
        information. Only authorized employees, agents, and contractors (who
        have agreed to keep information secure and confidential) have access to
        this information. All emails and newsletters from this site allow you to
        opt out of further mailings.</span
      >
    </p>
    <p class="c5 c9">
      <span
        >The Company will notify Users and relevant supervisory authorities of
        any data breach that affects personal identification information in
        accordance with applicable law, including without undue delay and, where
        required by law, within 72 hours of becoming aware of such breach. Such
        notification will include the nature of the breach, likely consequences,
        measures taken or proposed to address the breach, and contact
        information for inquiries, unless the personal data breach is unlikely
        to result in a risk to the rights and freedoms of natural persons.</span
      ><sup><a href="#cmnt3" id="cmnt_ref3">[c]</a></sup>
    </p>
    <h2 class="c3"><span class="c8">Your Rights</span></h2>
    <p class="c5 c9">
      <span class="c2"
        >As a User of All Angles Inc services, you have the right to:</span
      >
    </p>
    <ul class="c10 lst-kix_list_6-0 start">
      <li class="c6 li-bullet-0">
        <span class="c2"
          >Be informed about how your personal identification information is
          being used.</span
        >
      </li>
      <li class="c7 c5 li-bullet-0">
        <span class="c2"
          >Access the personal identification information about you that is held
          by All Angles Inc.</span
        >
      </li>
      <li class="c7 c5 li-bullet-0">
        <span class="c2"
          >Request the correction of inaccurate personal identification
          information held about you by All Angles Inc.</span
        >
      </li>
      <li class="c7 c5 li-bullet-0">
        <span class="c2"
          >Request the deletion of your personal identification information when
          it is no longer necessary for All Angles Inc to retain such
          data.</span
        >
      </li>
      <li class="c7 c5 li-bullet-0">
        <span class="c2"
          >Object to the processing of your personal identification information
          by All Angles Inc in certain circumstances.</span
        >
      </li>
      <li class="c7 c5 li-bullet-0">
        <span class="c2"
          >Request that All Angles Inc restrict the processing of your personal
          identification information under certain circumstances.</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c2"
          >Request the transfer of your personal identification information to
          another party.</span
        >
      </li>
    </ul>
    <p class="c5 c9">
      <span class="c2"
        >This Privacy Policy is governed by the laws of Lake County, Illinois.
        If you have any questions or concerns about our use of your personal
        identification information, please contact us at the provided contact
        details.</span
      >
    </p>
    <h2 class="c3"><span class="c8">Third-Party Services</span></h2>
    <p class="c5 c9">
      <span class="c2"
        >All Angles Inc ("We", "Us", or "Our") may engage third-party service
        providers ("Service Providers") to perform various functions on Our
        behalf. These functions include, but are not limited to, hosting and
        maintaining Our website, processing payments, conducting analysis or
        research, and assisting with marketing efforts. When engaging such
        Service Providers, We may share User's personal identification
        information with them to the extent necessary for them to perform their
        services.</span
      >
    </p>
    <p class="c5 c9">
      <span class="c2"
        >We require all Service Providers to adhere to strict confidentiality
        obligations and to implement adequate security measures, including SSL
        encryption, to protect the personal identification information they
        process on Our behalf. Despite these precautions, We cannot guarantee
        the security of User's personal identification information against all
        potential security breaches.</span
      >
    </p>
    <p class="c5 c9">
      <span
        >Users should be aware that some Service Providers may be located or
        operate in jurisdictions outside of Lake County, Illinois, and the
        United States. Any international transfer of personal identification
        information will be conducted in accordance with applicable data
        protection laws, including but not limited to the implementation of
        appropriate safeguards such as Standard Contractual Clauses, adequacy
        decisions, or other legally recognized transfer mechanisms. Users'
        personal identification information may be subject to the laws of the
        jurisdictions in which those Service Providers operate.</span
      ><sup><a href="#cmnt4" id="cmnt_ref4">[d]</a></sup
      ><span class="c2"
        >&nbsp;By using Our services, Users consent to the transfer of their
        personal identification information to these jurisdictions.</span
      >
    </p>
    <p class="c5 c9">
      <span class="c2"
        >We do not sell, trade, or otherwise transfer to outside parties User's
        personal identification information without User consent, except as
        described in this Privacy Policy. This does not include website hosting
        partners and other parties who assist Us in operating Our website,
        conducting Our business, or serving Our Users, so long as those parties
        agree to keep this information confidential.</span
      >
    </p>
    <h2 class="c3"><span class="c8">Children's Privacy</span></h2>
    <p class="c5 c9">
      <span class="c2"
        >All Angles Inc recognizes the importance of protecting the privacy of
        children in the interactive online world. The services provided by All
        Angles Inc are not designed for or intentionally targeted at children
        under the age of 13 years. It is not our policy to intentionally collect
        or maintain information about anyone under the age of 13.</span
      >
    </p>
    <p class="c5 c9">
      <span
        >No part of our website is structured to attract anyone under the age of
        13. Consistent with the Children's Online Privacy Protection Act
        (COPPA), we will never knowingly request personal identification
        information from anyone under the age of 13 without requiring parental
        consent. </span
      ><span
        >If we become aware that a child under 13 has provided us with personal
        identification information without verifiable parental consent, we will
        promptly: (1) delete all personal identification information collected
        from the child, (2) terminate the child's account, (3) notify and obtain
        verification from the child's parent or guardian, and (4) maintain
        records of such incidents as required by applicable law.</span
      ><sup><a href="#cmnt5" id="cmnt_ref5">[e]</a></sup>
    </p>
    <p class="c5 c9">
      <span class="c2"
        >For the purpose of this clause, User refers to an individual who uses
        the services provided by All Angles Inc, and personal identification
        information is information that can be used on its own or with other
        information to identify, contact, or locate a single person, or to
        identify an individual in context.</span
      >
    </p>
    <h2 class="c3"><span class="c8">Changes to This Privacy Policy</span></h2>
    <p class="c5 c9">
      <span class="c2"
        >All Angles Inc reserves the right to update or change our Privacy
        Policy at any time and you should check this Privacy Policy
        periodically. Your continued use of the service provided by All Angles
        Inc after we post any modifications to the Privacy Policy on this page
        will constitute your acknowledgment of the modifications and your
        consent to abide and be bound by the modified Privacy Policy.</span
      >
    </p>
    <p class="c5 c9">
      <span
        >If we make any material changes to this Privacy Policy, we will notify
        you at least 30 days prior to the changes taking effect either through
        the email address you have provided us, or by placing a prominent notice
        on our website. Where required by applicable law, we will obtain your
        consent before implementing material changes. Your continued use of our
        Services after such changes become effective constitutes your
        acknowledgment and acceptance of the modified Privacy Policy, where
        permitted by law.</span
      ><sup><a href="#cmnt6" id="cmnt_ref6">[f]</a></sup>
    </p>
    <h2 class="c3"><span class="c8">Contact Information</span></h2>
    <p class="c5 c9">
      <span class="c2"
        >If you have any questions or concerns regarding this Privacy Policy or
        the handling of your personal identification information by All Angles
        Inc, please contact us at the following:</span
      >
    </p>
    <ul class="c10 lst-kix_list_7-0 start">
      <li class="c6 li-bullet-0">
        <span class="c2">Email: info&#64;goallangles.com</span>
      </li>
      <li class="c0 li-bullet-0">
        <span class="c2"
          >Postal Address: All Angles Inc, 829 Waukegan Road, Deerfield, IL
          60015</span
        >
      </li>
    </ul>
    <p class="c5 c9">
      <span class="c2"
        >Our dedicated team is committed to addressing your inquiries and
        providing the necessary assistance regarding your privacy rights and the
        information collected by us.</span
      >
    </p>
    <div class="c4">
      <p class="c5">
        <a href="#cmnt_ref1" id="cmnt1">[a]</a
        ><span class="c1"
          >The original text only covered the website, but the policy actually
          covers all digital services including mobile apps as mentioned in
          later sections. The modification expands the scope to accurately
          reflect all services covered and maintains consistency throughout the
          document.</span
        >
      </p>
    </div>
    <div class="c4">
      <p class="c5">
        <a href="#cmnt_ref2" id="cmnt2">[b]</a
        ><span class="c1"
          >The original text oversimplifies data collection requirements and
          could be misleading regarding legal bases for processing. The
          modification better aligns with GDPR and other privacy law
          requirements by clarifying the legal basis for data collection and the
          implications of not providing information.</span
        >
      </p>
    </div>
    <div class="c4">
      <p class="c5">
        <a href="#cmnt_ref3" id="cmnt3">[c]</a
        ><span class="c1"
          >The modification provides more comprehensive breach notification
          requirements, including notification to supervisory authorities and
          specific content requirements for breach notifications, better
          aligning with GDPR and other data protection regulations.</span
        >
      </p>
    </div>
    <div class="c4">
      <p class="c5">
        <a href="#cmnt_ref4" id="cmnt4">[d]</a
        ><span class="c1"
          >The modification addresses international data transfer requirements
          more comprehensively, specifically mentioning appropriate safeguards
          required by data protection laws like GDPR for international data
          transfers.</span
        >
      </p>
    </div>
    <div class="c4">
      <p class="c5">
        <a href="#cmnt_ref5" id="cmnt5">[e]</a
        ><span class="c1"
          >The modification provides more specific steps for COPPA compliance,
          including verification requirements and record-keeping
          obligations.</span
        >
      </p>
    </div>
    <div class="c4">
      <p class="c5">
        <a href="#cmnt_ref6" id="cmnt6">[f]</a
        ><span class="c1"
          >The modification adds a specific notice period, consent requirements
          where legally necessary, and clarifies the implications of continued
          use, providing better protection for users and compliance with various
          privacy laws.</span
        >
      </p>
    </div>
  </div>
</div>
