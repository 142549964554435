<div
  class="items"
  [ngStyle]="{
    height: (open$ | async) ? menuItems.length * 6.6 + 'rem' : '0'
  }">
  <!-- @if (open$ | async) { -->
  @for (item of menuItems; track $index) {
    @if (item.href) {
      <a
        mat-fab
        color="primary"
        (click)="item.onClick ? item.onClick() : null"
        [href]="item.href"
        [matTooltip]="$any(item.tooltip)"
        matTooltipPosition="left"
        #tooltipRef="matTooltip">
        <mat-icon>{{ item.icon }}</mat-icon>
      </a>
    } @else {
      <button
        mat-fab
        color="primary"
        (click)="item.onClick ? item.onClick() : null"
        [matTooltip]="$any(item.tooltip)"
        matTooltipPosition="left"
        #tooltipRef="matTooltip">
        <mat-icon>{{ item.icon }}</mat-icon>
      </button>
    }
  }
  <!-- } -->
</div>
<button id="menu-button" mat-fab (click)="toggleOpen()" color="primary">
  <mat-icon>{{ (open$ | async) ? 'close' : menuIcon }}</mat-icon>
</button>
