import {
  CoreAppState,
  InvoiceHistoryComponent,
  orderActions,
  orderCardActions,
  selectOrderCardState,
  selectOrderState,
} from '@aa/angular/core';
import {
  OrderCardResourceTypeMappings,
  OrderResourceTypeMappings,
} from '@aa/nest/resource';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom, lastValueFrom, map, Observable } from 'rxjs';
import { ViewBarComponent } from '../../components/view-bar/view-bar.component';
import { ReviewCardsComponent } from '../../components/review-cards/review-cards.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NextStepsComponent } from '../../components/next-steps/next-steps.component';
import { OrderListCard } from '../../components/order-list-card/order-list-card.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'aa-order-review-view',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    ViewBarComponent,
    NextStepsComponent,
    ReviewCardsComponent,
    InvoiceHistoryComponent,
    OrderListCard,
  ],
  templateUrl: './order-review-view.component.html',
  styleUrl: './order-review-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderReviewViewComponent {
  orderId$: Observable<number>;
  order$: Observable<
    OrderResourceTypeMappings['resourceWithRelationsT'] | null
  >;

  cards$: Observable<OrderCardResourceTypeMappings['resourceWithRelationsT'][]>;

  constructor(
    private readonly store: Store<CoreAppState>,
    private readonly route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    this.orderId$ = this.route.paramMap.pipe(
      map((paramMap) => parseInt(paramMap.get('orderId')!)),
      takeUntilDestroyed(),
    );

    this.route.queryParams
      .pipe(
        map((queryParams) => queryParams['showShippingInfo'] == 'true'),
        takeUntilDestroyed(),
      )
      .subscribe((showShippingInfo) => {
        if (showShippingInfo) {
          this.showShippingInfo();
        }
      });
    this.order$ = this.store
      .select((s) => selectOrderState(s).current)
      .pipe(takeUntilDestroyed());
    this.cards$ = this.store
      .select((s) => selectOrderCardState(s).items)
      .pipe(takeUntilDestroyed());

    this.orderId$.subscribe((orderId) => {
      this.store.dispatch(
        orderActions.loadItem({
          id: orderId,
          include: {
            orderCards: true,
            orderStatus: true,
          },
        }),
      );
      this.store.dispatch(
        orderCardActions.loadItems({
          query: {
            where: {
              orderId,
            },
            include: {
              orderCardAttributes: {
                include: {
                  orderCardAttributeType: true,
                },
              },
              orderCardServiceLinkages: {
                include: {
                  offeredService: true,
                },
              },
            },
          },
        }),
      );
    });
  }

  async rejectChanges() {
    const orderId = await firstValueFrom(this.orderId$);
    this.store.dispatch(
      orderActions.setChangeApprovalStatus({
        id: orderId,
        approved: false,
      }),
    );
  }

  async approveChanges() {
    const orderId = await firstValueFrom(this.orderId$);
    this.store.dispatch(
      orderActions.setChangeApprovalStatus({
        id: orderId,
        approved: true,
      }),
    );
  }

  async showShippingInfo() {
    await lastValueFrom(
      this.dialog
        .open(NextStepsComponent, {
          autoFocus: false,
          data: {
            isModal: true,
          },
          maxWidth: 'calc(100vw - 1rem)',
          maxHeight: 'calc(100vh - 1rem)',
        })
        .afterClosed(),
    );
  }
}
