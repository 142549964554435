<aa-view-bar
  title="Checkout"
  [backPath]="
    'draft-editor/' + (orderId$ | async) + '/review-cards'
  "></aa-view-bar>

<div class="customer-view-container v-scrollable">
  @if (loading$ | async) {
    <mat-spinner></mat-spinner>
  } @else {
    <div class="customer-view-sections">
      <div class="section">
        <aa-invoice
          [invoice]="
            (invoiceAndPaymentDetails$ | async)?.invoice!
          "></aa-invoice>
      </div>

      <div class="section">
        <h2>Payment</h2>
        <form id="payment-form">
          <div id="payment-element">
            <!--Stripe.js injects the Payment Element-->
          </div>
          <button
            mat-flat-button
            color="primary"
            id="pay-button"
            (click)="submit($event)">
            <div class="spinner hidden" id="spinner"></div>
            <span id="button-text">Pay now</span>
          </button>
          <div id="payment-message" class="hidden"></div>
        </form>
      </div>
    </div>
  }
</div>
