<aa-view-bar title="Order Review" backPath="/"></aa-view-bar>

<div class="customer-view-container v-scrollable">
  @if ((orderId$ | async) && (order$ | async)) {
    <h1>Order #O_{{ orderId$ | async }}</h1>

    <aa-order-list-card
      [order]="(order$ | async)!"
      [asHeader]="true"></aa-order-list-card>

    <div class="status"></div>

    @if ((order$ | async)?.customerFacingNotes) {
      <div class="staff-notes">
        <h2>Staff Notes</h2>
        <p>{{ (order$ | async)?.customerFacingNotes }}</p>
      </div>
    }

    @if (
      (order$ | async)?.changesApproved == null &&
      (order$ | async)?.showChangesToCustomer
    ) {
      <div class="drafted-changes-section">
        <p>
          This order has some suggested changes as noted below. Please review
          these changes and either approve them or reject them.
          <br /><br />

          If you would like to discuss these changes further please either call
          <a href="tel:1231231234">+1&nbsp;(123)&nbsp;123&#8209;1234</a> or
          email us at
          <a href="mailto:contact@allangles.ai">contact&#64;allangles.ai</a>.
        </p>

        <div class="actions">
          <button id="reject-button" mat-flat-button (click)="rejectChanges()">
            Reject Changes
            <mat-icon iconPositionEnd>close</mat-icon>
          </button>
          <button
            id="approve-button"
            mat-flat-button
            (click)="approveChanges()">
            Approve Changes <mat-icon iconPositionEnd>check</mat-icon>
          </button>
        </div>
      </div>
    }

    <aa-review-cards [orderId]="(orderId$ | async)!"></aa-review-cards>
    <aa-invoice-history
      [orderId]="(orderId$ | async)!"
      [showAsCustomerView]="true"></aa-invoice-history>
  }
</div>
