<aa-view-bar backPath="/" title="Order Payment"></aa-view-bar>

@if (loading$ | async) {
  <div class="loading-container">
    <mat-spinner></mat-spinner>
  </div>
} @else {
  <div class="customer-view-container v-scrollable">
    @if (isSuccess$ | async) {
      <h2>Order Payment Processed Successfully</h2>
      <p>Your payment has been processed.</p>
    } @else {
      <h2>Order Payment Failed</h2>
      <p>Failed to Process Payment</p>
    }
  </div>
}
